import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ChooseSystem from '../views/ChooseSystem.vue'
import {wsutil} from '../store'
import {jwt} from "../assets/js/decodejwt";
import {nav} from '@/nav/nav'
import * as AppStorage from '../assets/js/storage';

let entryUrl = null;

const guard = async (to, from, next) => {
  if (AppStorage.get("jwtToken")) {
    let getparsed = jwt.parse(AppStorage.get("jwtToken"));
    let profiletr = getparsed.profiletr;

    if (profiletr == 'N/A'){
        next(); // all is fine
    }

    else{
      next('/');
    }
  }

  else{
    next('/');
  }
};

const guardafterretoken = async (to, from, next) => {
  if (wsutil.getters.getJwt) {
      let getparsed = jwt.parse(wsutil.getters.getJwt);
      let profiletr = getparsed.profiletr;
      if (profiletr == 'N/A'){
        entryUrl = to.path; // store entry url before redirect
        next('/selectsys');
      }

      else{
        next(); // all is fine
      }
  }

  else{
      entryUrl = to.path; // store entry url before redirect
      next('/selectsys');
  }
};

const admin_guard = async (to, from, next) => {
  let response = await wsutil.dispatch("viewAccess", {});

  let apiurl;
  if (to.meta.parent == undefined) { apiurl = await nav.getApiPath(to.name); }
  else { apiurl = await nav.getApiPath(to.name, to.meta.parent); }

  if (response.data.code == 'AP000'){
    let item = response.data.record.find((item2) => {
      return item2.Path == apiurl;
    });

    console.log(item);

    let pathAcceptedRoles = item.TypeRole.split(', ');
    let check = [];

    if (AppStorage.get("jwtToken") !== null){
      let truth;
      for(var i=0; i<pathAcceptedRoles.length; i++){
        let formattedstring1 = pathAcceptedRoles[i].replace('[', '');
        let formattedstring2 = formattedstring1.replace(']', '');

        let gettyperole = wsutil.getters.getPerm.split(",")[0] + "#" + wsutil.getters.getPerm.split(",")[1];
        if (gettyperole == formattedstring2)
          check.push(true);
        else check.push(false);

        truth = check.some(i => i == true);
      }
      
      if (!truth) {
        next('/restricted');
      } else {
          next();
      }
    }

    else{
      next('/');
    }
  }

  else{
    next('/');
  }
};

const fPage = async (to, from, next) => {
  let list = await nav.retrieve();
  let name = '';
  let firstList = await list.find(i => i.permission == true);
  let checkAllnavs = await list.every(i => i.permission == false);

  if (!checkAllnavs){
    if (!firstList.hasChild) name = firstList.url;

    else{
      let firstChildList = firstList.children.find(i => i.permission == true);

      if (firstChildList.hasChild){
        let secondList = firstChildList.children.find(i => i.permission == true);
        if (!secondList.hasChild) name = secondList.url;
      }
      else
      name = firstChildList.url;
    }

    console.log('first', name);
    console.log('to', to.path);

    if (AppStorage.get("jwtToken") !== null){
        if (to.path == '/' || to.path == '/home' || to.path == '/home/')
          next(name);

        else{
          next();
        }
    }

    else{
      next('/');
    }
  }

  else{
    wsutil.dispatch('userLogout',{
      router: router
    });
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/login'
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      if (AppStorage.get("jwtToken") !== null) {
          next('/home')
      }

      else{
          next()
      }
  },
  },

  {
    path: "/selectsys",
    name: "Select System",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Select System" */ '../views/ChooseSystem.vue'),
    beforeEnter: guard, // Using guard before entering the route
  },

  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: fPage,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '../components/Dashboard.vue'),
        meta: { parent: '' },
        beforeEnter: admin_guard, // Using guard before entering the route
      },

      //{
        //path: "administration/createuser",
        //name: "Create User",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "NotFound" */ '../components/administration/CreateUser.vue'),
        //beforeEnter: admin_guard
      //},

      {
        path: "administration/usermanagement/internalusers",
        name: "Internal Users",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Internal Users" */ '../components/administration/Users2.vue'),
        meta: { parent: 'administration - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "administration/usermanagement/internalusers/profile/:id",
        name: "User Profile Details",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "User Profile Details" */ '../components/administration/UserProfileDetails.vue'),
        meta: { parent: 'administration - user management' },
        beforeEnter: admin_guard,
      },

      {
        path: "administration/usermanagement/internalusers/soc/:id",
        name: "Admin SOC",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Admin SOC" */ '../components/administration/ViewAdminSOC.vue'),
        meta: { parent: 'administration - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "administration/usermanagement/profiletype",
        name: "Profile Type",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Profile Type" */ '../components/administration/ProfileType.vue'),
        meta: { parent: 'administration - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "administration/usermanagement/role",
        name: "Role",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Role" */ '../components/administration/ProfileRole.vue'),
        meta: { parent: 'administration - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "administration/usermanagement/rolepermission",
        name: "Role Permission",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Role Permission" */ '../components/administration/Roles.vue'),
        meta: { parent: 'administration - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "administration/location/country",
        name: "Country",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Country" */ '../components/location/Country.vue'),
        meta: { parent: 'administration - location' },
        beforeEnter: admin_guard,
      },

      {
        path: "administration/location/state",
        name: "State",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "State" */ '../components/location/State.vue'),
        meta: { parent: 'administration - location' },
        beforeEnter: admin_guard,
      },

      {
        path: "administration/location/city",
        name: "City",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "City" */ '../components/location/City.vue'),
        meta: { parent: 'administration - location' },
        beforeEnter: admin_guard,
      },

      {
        path: "administration/location/postcode",
        name: "Postcode",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Postcode" */ '../components/location/Postcode.vue'),
        meta: { parent: 'administration - location' },
        beforeEnter: admin_guard,
      },

      {
        path: "administration/system",
        name: "System",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "System" */ '../components/system/System.vue'),
        meta: { parent: 'administration' },
        beforeEnter: admin_guard
      },

      {
        path: "administration/holiday",
        name: "Holiday",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Holiday" */ '../components/administration/Holiday.vue'),
        meta: { parent: 'administration' },
        beforeEnter: admin_guard
      },

      {
        path: "administration/parkingentry/category",
        name: "Manual Exit Category",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Holiday" */ '../components/administration/ManualExitCategory.vue'),
        meta: { parent: 'administration - parking entry' },
        beforeEnter: admin_guard
      },

      {
        path: "administration/parkingentry/issue",
        name: "Manual Exit Issue",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Holiday" */ '../components/administration/ManualExitIssue.vue'),
        meta: { parent: 'administration - parking entry' },
        beforeEnter: admin_guard
      },

      {
        path: "business/cpoperator/operator",
        name: "Operator",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Operator" */ '../components/operator/Operator.vue'),
        meta: { parent: 'business - car park operator' },
        beforeEnter: admin_guard
      },

      {
        path: "business/cpoperator/user",
        name: "Users",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Users" */ '../components/operator/Users.vue'),
        meta: { parent: 'business - car park operator' },
        beforeEnter: admin_guard
      },
      

      {
        path: "business/cpoperator/user/update/:id",
        name: "Operator Profile Details",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Operator Profile Details" */ '../components/operator/OperatorProfileDetails.vue'),
        meta: { parent: 'business - car park operator' },
        beforeEnter: admin_guard,
      },

      {
        path: "business/cpoperator/user/update/:id/roledescription/:role_id/:type_id",
        name: "Role Description",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Role Description" */ '../components/operator/RolePermissionsView.vue'),
        meta: { parent: 'business - car park operator' },
        beforeEnter: admin_guard,
      },

      {
        path: "business/cpoperator/user/create",
        name: "Create User",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Create User" */ '../components/operator/CreateOperatorProfile.vue'),
        meta: { parent: 'business - car park operator' },
        beforeEnter: admin_guard,
      },

      {
        path: "carpark/carpark",
        name: "Car Park",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Car Park" */ '../components/carpark/Carpark.vue'),
        meta: { parent: 'car park' },
        beforeEnter: admin_guard
      },

      {
        path: "carpark/carpark/update/:id",
        name: "Update Car Park",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Update Car Park" */ '../components/carpark/UpdateCarpark.vue'),
        meta: { parent: 'car park' },
        beforeEnter: admin_guard
      },

      {
        path: "carpark/carpark/create",
        name: "Create Car Park",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Create Car Park" */ '../components/carpark/CreateCarpark.vue'),
        meta: { parent: 'car park' },
        beforeEnter: admin_guard
      },

      {
        path: "carpark/bays",
        name: "Bays",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Bays" */ '../components/carpark/Bays.vue'),
        meta: { parent: 'car park' },
        beforeEnter: admin_guard
      },

      {
        path: "carpark/findmycar",
        name: "Find My Car",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Find My Car" */ '../components/carpark/FindMyCar.vue'),
        meta: { parent: 'car park' },
        beforeEnter: admin_guard
      },

      {
        path: "carpark/serviceuser",
        name: "Service User",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Service User" */ '../components/carpark/ServiceUser.vue'),
        meta: { parent: 'car park' },
        beforeEnter: admin_guard
      },

      {
        path: "carpark/serviceuser/create",
        name: "Create Service User",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Create Service User" */ '../components/carpark/CreateServiceUser.vue'),
        meta: { parent: 'car park' },
        beforeEnter: admin_guard,
      },

      {
        path: "administration/usermanagement/rolepermission/:role_id/:type_id",
        name: "Admin Permission",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Admin Permission" */ '../components/administration/AdminPermission.vue'),
        meta: { parent: 'administration - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "spconsolesetting/usersetting/users/:role_id/:type_id",
        name: "Permissions",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Permissions" */ '../components/customer/Permissions.vue'),
        meta: { parent: 'sp console setting - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "customer/users",
        name: "Users",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Users" */ '../components/customer/Users.vue'),
        meta: { parent: 'customer' },
        beforeEnter: admin_guard
      },

      {
        path: "spconsolesetting/usersetting/profiletype",
        name: "Profile Type",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Profile Type" */ '../components/customer/ProfileType.vue'),
        meta: { parent: 'sp console setting - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "spconsolesetting/usersetting/profilerole",
        name: "Role",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Role" */ '../components/customer/ProfileRole.vue'),
        meta: { parent: 'sp console setting - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "spconsolesetting/usersetting/users",
        name: "Role Permission",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Role Permission" */ '../components/customer/CustomerRoles.vue'),
        meta: { parent: 'sp console setting - user management' },
        beforeEnter: admin_guard
      },

      {
        path: "carpark/ratesetup",
        name: "Car Park Rate",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Car Park Rate" */ '../components/ratecard/RateCard.vue'),
        beforeEnter: admin_guard,
        meta: { parent: 'car park' },
      },

      {
        path: "carpark/ratesetup/createratecard",
        name: "Create Rate Card",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Create Rate Card" */ '../components/ratecard/CreateRateCard.vue'),
        beforeEnter: admin_guard,
        meta: { parent: 'car park' },
        //beforeEnter: admin_guard
      },

      {
        path: "carpark/ratesetup/update/:id",
        name: "Update Car Park Rate",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Update Car Park Rate" */ '../components/ratecard/UpdateRateCard.vue'),
        beforeEnter: admin_guard,
        meta: { parent: 'car park' },
      },

      {
        path: "carpark/ratesetup/create",
        name: "Create Car Park Rate",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Create Car Park Rate" */ '../components/ratecard/CreateRateCard2.vue'),
        beforeEnter: admin_guard,
        meta: { parent: 'car park' },
      },

      {
        path: "carpark/specialrate",
        name: "Special Car Park Rate",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Special Car Park Rate" */ '../components/carpark/SpecialCarParkRate.vue'),
        beforeEnter: admin_guard,
        meta: { parent: 'car park' },
      },

      {
        path: "marketing/valuepass/valuepass",
        name: "Value Pass",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Value Pass" */ '../components/valuepass/ValuePass.vue'),
        meta: { parent: 'marketing - value pass' },
        beforeEnter: admin_guard,
      },

      {
        path: "marketing/valuepass/valuepassunits",
        name: "Value Pass Units",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Value Pass Units" */ '../components/valuepass/ValuePassUnits.vue'),
        meta: { parent: 'marketing - value pass' },
        beforeEnter: admin_guard,
      },

      {
        path: "marketing/merchantreward/slots",
        name: "Merchant Reward Slots",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Merchant Reward Slots" */ '../components/merchant/MerchantRewardSlots.vue'),
        meta: { parent: 'marketing - merchant reward' },
        beforeEnter: admin_guard,
      },

      {
        path: "marketing/merchantreward/merchantrewards",
        name: "Merchant Rewards",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Merchant Rewards" */ '../components/merchant/MerchantRewards.vue'),
        meta: { parent: 'marketing - merchant reward' },
        beforeEnter: admin_guard,
      },

      {
        path: "marketing/merchantreward/reviewreasons",
        name: "Review Reasons",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Review Reasons" */ '../components/merchant/ReviewReasons.vue'),
        meta: { parent: 'marketing - merchant reward' },
        beforeEnter: admin_guard,
      },

      {
        path: "marketing/merchantreward/collectedpromovouchers",
        name: "Collected Promo Vouchers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Collected Promo Vouchers" */ '../components/merchant/CollectedPromoVouchers.vue'),
        meta: { parent: 'marketing - merchant reward' },
        beforeEnter: admin_guard,
      },

      {
        path: "marketing/merchantreward/merchantrewards/create",
        name: "Create Merchant Rewards",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Create Merchant Rewards" */ '../components/merchant/CreateMerchantRewards.vue'),
        meta: { parent: 'marketing - merchant reward' },
        beforeEnter: admin_guard,
      },

      {
        path: "marketing/merchantreward/merchantrewards/update/:id/:loginemailid",
        name: "Update Merchant Rewards",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Update Merchant Rewards" */ '../components/merchant/UpdateMerchantRewards.vue'),
        meta: { parent: 'marketing - merchant reward' },
        beforeEnter: admin_guard,
      },

      {
        path: "marketing/valuepass/valuepass/create",
        name: "Create",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Create VP" */ '../components/valuepass/Create.vue'),
        meta: { parent: 'marketing - value pass' },
        beforeEnter: admin_guard,
      },

      {
        path: "marketing/valuepass/valuepass/update/:id",
        name: "Update",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Update VP" */ '../components/valuepass/Update.vue'),
        meta: { parent: 'marketing - value pass' },
        beforeEnter: admin_guard,
      },

      {
        path: "message/sms",
        name: "SMS",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SMS" */ '../components/message/SMS.vue'),
        meta: { parent: 'message' },
        beforeEnter: admin_guard,
      },

      {
        path: "message/blast",
        name: "New Message Blast",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "New Message Blast" */ '../components/message/NewMessageBlast.vue'),
        meta: { parent: 'message' },
        beforeEnter: admin_guard,
      },

      {
        path: "message/pushnotification/inbox",
        name: "Inbox",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Inbox" */ '../components/message/Inbox.vue'),
        meta: { parent: 'message - push notification' },
        beforeEnter: admin_guard,
      },

      {
        path: "message/pushnotification/notification",
        name: "Notification",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Notification" */ '../components/message/Notification.vue'),
        meta: { parent: 'message - push notification' },
        beforeEnter: admin_guard,
      },

      {
        path: "message/pushnotification/messagecategory",
        name: "Message Category",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Message Category" */ '../components/message/MessageCategory.vue'),
        meta: { parent: 'message - push notification' },
        beforeEnter: admin_guard,
      },

      {
        path: "message/whatsapp/messagetemplate",
        name: "Message Template",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Message Template" */ '../components/message/WhatsAppMessageTemplate.vue'),
        meta: { parent: 'message - whatsapp' },
        beforeEnter: admin_guard,
      },

      {
        path: "message/whatsapp/whatsapp",
        name: "WhatsApp",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "WhatsApp" */ '../components/message/WhatsApp.vue'),
        meta: { parent: 'message - whatsapp' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/purchasevaluepass",
        name: "Purchased Value Pass",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Purchased Value Pass TRXN" */ '../components/transaction/PurchaseValuePass.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/purchasedparkingtokens",
        name: "Purchased Parking Tokens",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Purchased Parking Tokens TRXN" */ '../components/transaction/PurchasedParkingTokens.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/transferredparkingtokens",
        name: "Transferred Parking Tokens",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Transferred Parking Tokens TRXN" */ '../components/transaction/TransferredParkingTokensTransaction.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/freeparkingtokens",
        name: "Free Parking Tokens",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Free Parking Tokens TRXN" */ '../components/transaction/FreeParkingTokens.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/deductparkingtokens",
        name: "Deduct Parking Tokens",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Deduct Parking Tokens TRXN" */ '../components/transaction/DeductParkingTokensTransaction.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/parking",
        name: "Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Parking TRXN" */ '../components/transaction/Parking.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },
      {
        path: "transaction/mobilesub/parkinglpr",
        name: "LPR Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "LPR Parking TRXN" */ '../components/transaction/LprParking.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/specialrateparking",
        name: "Special Rate Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Special Rate Parking TRXN" */ '../components/transaction/SpecialRateParkingTransaction.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/manualexit",
        name: "Manual Exit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Manual Exit TRXN" */ '../components/transaction/ManualExitTransaction.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },
      {
        path: "transaction/mobilesub/manualentry",
        name: "Manual Entry",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Manual Entry TRXN" */ '../components/transaction/ManualEntryTransaction.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },
      
      {
        path: "transaction/mobilesub/lprexit",
        name: "LPR Exit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Manual Exit TRXN" */ '../components/transaction/LprTriggerHistory.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },
      {
        path: "transaction/mobilesub/lprentry",
        name: "LPR Manual Entry",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Manual Exit TRXN" */ '../components/transaction/LprTriggerEntryHistory.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },
      {
        path: "transaction/mobilesub/updateplate",
        name: "Update Plate",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Manual Exit TRXN" */ '../components/transaction/LprTriggerUpdatePlateHistory.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/paymentgateway/ipay88online",
        name: "iPay88 Online",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "iPay88 Online TRXN" */ '../components/transaction/IPAY88OnlineTransaction.vue'),
        meta: { parent: 'transaction - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/paymentgateway/boost",
        name: "Boost",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Boost TRXN" */ '../components/transaction/BoostTransaction.vue'),
        meta: { parent: 'transaction - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/paymentgateway/binfinitepay",
        name: "BInfinite Pay",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "BInfinite Pay TRXN" */ '../components/transaction/BinfiniteTransaction.vue'),
        meta: { parent: 'transaction - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/paymentgateway/tng",
        name: "Touch N Go e-Wallet",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Touch N Go TRXN" */ '../components/transaction/TNGTransaction.vue'),
        meta: { parent: 'transaction - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/paymentgateway/duitnow",
        name: "Duit Now",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Duit Now TRXN" */ '../components/transaction/DuitNowTransaction.vue'),
        meta: { parent: 'transaction - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/paymentgateway/tngqr",
        name: "Touch N Go QR",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Touch N Go QR TRXN" */ '../components/transaction/TNGQRTransaction.vue'),
        meta: { parent: 'transaction - payment gateway' },
        beforeEnter: admin_guard,
      },
      
      {
        path: "transaction/paymentgateway/tngcard",
        name: "Touch N Go",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Touch N Go QR TRXN" */ '../components/transaction/TNGCardTransaction.vue'),
        meta: { parent: 'transaction - payment gateway' },
        beforeEnter: admin_guard,
      },
      
      {
        path: "transaction/paymentgateway/visamaster",
        name: "Visa Master",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Touch N Go QR TRXN" */ '../components/transaction/VisaMasterTransaction.vue'),
        meta: { parent: 'transaction - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/lprweb/payment",
        name: "Payment",
        component: () => import(/* webpackChunkName: "Touch N Go QR TRXN" */ '../components/transaction/LprWebPayment.vue'),
        meta: { parent: 'transaction - lpr web' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/sms/sentactivationcode",
        name: "Sent Activation Code",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Sent Activation Code TRXN" */ '../components/transaction/SentActivationCode.vue'),
        meta: { parent: 'transaction - sms' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/sms/verifiedactivationcode",
        name: "Verified Activation Code",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Verified Activation Code TRXN" */ '../components/transaction/VerifiedActivationCode.vue'),
        meta: { parent: 'transaction - sms' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/sms/smsnotification",
        name: "SMS Notification",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SMS Notification TRXN" */ '../components/transaction/SMSNotification.vue'),
        meta: { parent: 'transaction - sms' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/sms/allsms",
        name: "All SMS",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "All SMS TRXN" */ '../components/transaction/AllSMS.vue'),
        meta: { parent: 'transaction - sms' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/baylockparking/allparking",
        name: "All Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "All Parking TRXN" */ '../components/transaction/BayLockAllParking.vue'),
        meta: { parent: 'transaction - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/baylockparking/appparking",
        name: "App Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "App Parking TRXN" */ '../components/transaction/BayLockAppParking.vue'),
        meta: { parent: 'transaction - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/baylockparking/poleparking",
        name: "Pole Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Pole Parking TRXN" */ '../components/transaction/BayLockPoleParking.vue'),
        meta: { parent: 'transaction - baylock parking' },
        beforeEnter: admin_guard,
      },
      
      {
        path: "transaction/baylockparking/webpayment",
        name: "Web Payment",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Web Payment TRXN" */ '../components/transaction/BayLockWebPayment.vue'),
        meta: { parent: 'transaction - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/baylockparking/nopayment",
        name: "No Payment",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "No Payment TRXN" */ '../components/transaction/BayLockNoPaymentParking.vue'),
        meta: { parent: 'transaction - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/baylockparking/manualexit",
        name: "Manual Exit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Manual Exit BAYLOCK TRXN" */ '../components/transaction/BayLockManualExitTransaction.vue'),
        meta: { parent: 'transaction - baylock parking' },
        beforeEnter: admin_guard,
      },
      {
        path: "transaction/baylockparking/grace",
        name: "Grace Period",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Grace Period BAYLOCK TRXN" */ '../components/transaction/BayLockGracePeriodTransaction.vue'),
        meta: { parent: 'transaction - baylock parking' },
        beforeEnter: admin_guard,
      },
      {
        path: "transaction/baylockparking/manualtrigger",
        name: "Manual Trigger",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Grace Period BAYLOCK TRXN" */ '../components/transaction/BaylockManualTrigger.vue'),
        meta: { parent: 'transaction - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/corporatesubscriber/purchasedmerchantrewards",
        name: "Purchased Merchant Rewards",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Purchased Merchant Rewards TRXN" */ '../components/transaction/PurchasedMerchantRewards.vue'),
        meta: { parent: 'transaction - corporate subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/corporatesubscriber/collectedpromovouchers",
        name: "Collected Promo Vouchers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Collected Promo Vouchers TRXN" */ '../components/transaction/CollectedPromoVouchers.vue'),
        meta: { parent: 'transaction - corporate subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/corporatesubscriber/redeemedpromovouchers",
        name: "Redeemed Promo Vouchers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Redeemed Promo Vouchers TRXN" */ '../components/transaction/RedeemedPromoVouchers.vue'),
        meta: { parent: 'transaction - corporate subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/paymentgateway/ipay88online",
        name: "iPay88 Online",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/IPAY88Report.vue'),
        meta: { parent: 'report - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/paymentgateway/boost",
        name: "Boost",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/BoostReport.vue'),
        meta: { parent: 'report - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/paymentgateway/binfinitepay",
        name: "BInfinite Pay",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/BinfiniteReport.vue'),
        meta: { parent: 'report - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/paymentgateway/tng",
        name: "Touch N Go e-Wallet",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/TNGReport.vue'),
        meta: { parent: 'report - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/paymentgateway/duitnow",
        name: "Duit Now",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/DuitNowReport.vue'),
        meta: { parent: 'report - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/paymentgateway/tngqr",
        name: "Touch N Go QR",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/TNGQRReport.vue'),
        meta: { parent: 'report - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/paymentgateway/tngcard",
        name: "Touch N Go",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/TNGCardReport.vue'),
        meta: { parent: 'report - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/paymentgateway/visamaster",
        name: "Visa Master",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/VisaMasterReport.vue'),
        meta: { parent: 'report - payment gateway' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/valuepass/purchasedvaluepass",
        name: "Purchased Value Pass",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/PurchasedValuePass.vue'),
        meta: { parent: 'report - value pass' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/valuepass/usedvaluepass",
        name: "Used Value Pass",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/UsedValuePassReport.vue'),
        meta: { parent: 'report - value pass' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/valuepass/transferredvaluepass",
        name: "Transferred Value Pass",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/TransferredValuePassReport.vue'),
        meta: { parent: 'report - value pass' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/merchantrewards/purchasedmerchantrewards",
        name: "Purchased Merchant Rewards",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/PurchasedMerchantRewardsReport.vue'),
        meta: { parent: 'report - merchant rewards' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/merchantrewards/collectedpromovouchers",
        name: "Collected Promo Vouchers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/CollectedPromoVouchers.vue'),
        meta: { parent: 'report - merchant rewards' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/merchantrewards/redeemedpromovouchers",
        name: "Redeemed Promo Vouchers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/RedeemedPromoVouchers.vue'),
        meta: { parent: 'report - merchant rewards' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/parkingtokens/purchasedparkingtokens",
        name: "Purchased Parking Tokens",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/PurchasedParkingTokens.vue'),
        meta: { parent: 'report - parking tokens' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/parkingtokens/freeparkingtokens",
        name: "Free Parking Tokens",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/FreeParkingTokensReport.vue'),
        meta: { parent: 'report - parking tokens' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/parkingtokens/deductedparkingtokens",
        name: "Deducted Parking Tokens",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/DeductedParkingTokensReport.vue'),
        meta: { parent: 'report - parking tokens' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/parkingr/casualparking",
        name: "Casual Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/CasualParkingReport.vue'),
        meta: { parent: 'report - parking' },
        beforeEnter: admin_guard,
      },
      
      {
        path: "report/parkingr/specialrateparking",
        name: "Special Rate Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/SpecialRateParkingReport.vue'),
        meta: { parent: 'report - parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/parkingr/allparking",
        name: "All Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/AllParkingReport.vue'),
        meta: { parent: 'report - parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/parkingr/seasonparking",
        name: "Season Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/SeasonParkingReport.vue'),
        meta: { parent: 'report - parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/parkingr/manualexit",
        name: "Manual Exit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/ManualExitReport.vue'),
        meta: { parent: 'report - parking' },
        beforeEnter: admin_guard,
      },
      {
        path: "report/parkingr/manualentry",
        name: "Manual Entry",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/ManualEntryReport.vue'),
        meta: { parent: 'report - parking' },
        beforeEnter: admin_guard,
      },
      {
        path: "report/parkingr/lprexit",
        name: "LPR Exit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/LprTriggerHistory.vue'),
        meta: { parent: 'report - parking' },
        beforeEnter: admin_guard,
      },
      {
        path: "report/parkingr/lprentry",
        name: "LPR Manual Entry",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/LprTriggerEntryHistory.vue'),
        meta: { parent: 'report - parking' },
        beforeEnter: admin_guard,
      },
      {
        path: "report/parkingr/updateplate",
        name: "Update Plate",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/LprTriggerUpdatePlateHistory.vue'),
        meta: { parent: 'report - parking' },
        beforeEnter: admin_guard,
      },
      {
        path: "report/lprweb/payment",
        name: "Payment",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/LprWebPayment.vue'),
        meta: { parent: 'report - lpr web' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/operatorremittance/allproducts",
        name: "All Products",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/AllProducts.vue'),
        meta: { parent: 'report - operator remittance' },
        beforeEnter: admin_guard,
      },
      {
        path: "report/operatorremittance/reader",
        name: "reader",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/Reader.vue'),
        meta: { parent: 'report - operator remittance' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/baylockparking/allparking",
        name: "All Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/BayLockAllParkingReport.vue'),
        meta: { parent: 'report - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/baylockparking/appparking",
        name: "App Casual Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/BayLockAppParkingReport.vue'),
        meta: { parent: 'report - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/baylockparking/poleparking",
        name: "Pole Casual Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/BayLockPoleParkingReport.vue'),
        meta: { parent: 'report - baylock parking' },
        beforeEnter: admin_guard,
      },
      
      {
        path: "report/baylockparking/webpayment",
        name: "Web Casual Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/BayLockWebPaymentReport.vue'),
        meta: { parent: 'report - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/baylockparking/nopayment",
        name: "No Payment",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/BayLockNoPaymentParkingReport.vue'),
        meta: { parent: 'report - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/baylockparking/grace",
        name: "Grace Period",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/BayLockGracePeriodReport.vue'),
        meta: { parent: 'report - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "report/baylockparking/manualexit",
        name: "Manual Exit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/report/BayLockManualExitReport.vue'),
        meta: { parent: 'report - baylock parking' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/valuepassunits",
        name: "Value Pass Units",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/transaction/ValuePassUnitsTrxn.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/valuepasstransfer",
        name: "Transferred Value Pass",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/transaction/ValuePassUnitsTransfer.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/usedvaluepass",
        name: "Used Value Pass",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/transaction/UsedValuePassTransaction.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "transaction/mobilesub/history",
        name: "History",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/transaction/DriverHistory.vue'),
        meta: { parent: 'transaction - mobile subscriber' },
        beforeEnter: admin_guard,
      },

      {
        path: "subscriber/mobile/all",
        name: "All Subscribers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/SubscriberAll.vue'),
        meta: { parent: 'subscriber - mobile' },
        beforeEnter: admin_guard
      },

      {
        path: "subscriber/mobile/snatchp",
        name: "Snatch-P",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/SubscriberMobile.vue'),
        meta: { parent: 'subscriber - mobile' },
        beforeEnter: admin_guard
      },

      {
        path: "subscriber/mobile/tngqr",
        name: "Touch N Go QR",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/SubscriberTNGQR.vue'),
        meta: { parent: 'subscriber - mobile' },
        beforeEnter: admin_guard
      },

      {
        path: "subscriber/mobile/binfinite",
        name: "BInfinite Pay",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/SubscriberBInfinitePay.vue'),
        meta: { parent: 'subscriber - mobile' },
        beforeEnter: admin_guard
      },

      {
        path: "subscriber/mobile/carplate",
        name: "Car Plate",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/SubscriberCarPlate.vue'),
        meta: { parent: 'subscriber - mobile' },
        beforeEnter: admin_guard
      },

      {
        path: "subscriber/corporate/account",
        name: "Corporate Account",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/CorporateAccount.vue'),
        meta: { parent: 'subscriber - corporate' },
        beforeEnter: admin_guard
      },

      {
        path: "subscriber/season",
        name: "Season",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/SubscriberSeason.vue'),
        meta: { parent: 'subscriber' },
        beforeEnter: admin_guard
      },

      {
        path: "subscriber/vehicle",
        name: "Vehicle",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/SubscriberVehicle.vue'),
        meta: { parent: 'subscriber' },
        beforeEnter: admin_guard
      },
      
      {
        path: "subscriber/lprweb",
        name: "LPR Web",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/SubscriberLprWeb.vue'),
        meta: { parent: 'subscriber' },
        beforeEnter: admin_guard
      },
      
      {
        path: "subscriber/wlweb",
        name: "Baylock Web",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/subscriber/SubscriberWlWeb.vue'),
        meta: { parent: 'subscriber' },
        beforeEnter: admin_guard
      },

      {
        path: "parkingentry/gatedparking",
        name: "Gated Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/parkingentry/GatedParking.vue'),
        meta: { parent: 'parking entry' },
        beforeEnter: admin_guard
      },
      
      {
        path: "parkingentry/lprparking",
        name: "LPR Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/parkingentry/LprGatedParking.vue'),
        meta: { parent: 'parking entry' },
        beforeEnter: admin_guard
      },
      
      {
        path: "parkingentry/lpr/parking",
        name: "Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/parkingentry/LprGatedParking.vue'),
        meta: { parent: 'parking entry - lpr' },
        beforeEnter: admin_guard
      },
      {
        path: "parkingentry/lpr/images/all",
        name: "LPR Images",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/parkingentry/LprImage.vue'),
        meta: { parent: 'parking entry - lpr' },
        beforeEnter: admin_guard
      },
      {
        path: "parkingentry/lpr/images/unknown",
        name: "LPR Unknown",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/parkingentry/LprUnknown.vue'),
        meta: { parent: 'parking entry - lpr' },
        beforeEnter: admin_guard
      },

      {
        path: "parkingentry/baylockparking",
        name: "BayLock Parking",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/parkingentry/BayLockParking.vue'),
        meta: { parent: 'parking entry' },
        beforeEnter: admin_guard
      },

      {
        path: "boomgate/controller/controller",
        name: "Controller",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/boomgate/Controller.vue'),
        meta: { parent: 'boom gate - controller' },
        beforeEnter: admin_guard
      },

      {
        path: "boomgate/controller/offlinetransaction",
        name: "Offline Transaction",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/boomgate/OfflineTransaction.vue'),
        meta: { parent: 'boom gate - controller' },
        beforeEnter: admin_guard
      },

      {
        path: "boomgate/boomgate",
        name: "Boom Gate",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/boomgate/Boomgate.vue'),
        meta: { parent: 'boom gate' },
        beforeEnter: admin_guard
      },

      {
        path: "baylock/controller/controller",
        name: "Controller",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/wheellock/WheelLockController.vue'),
        meta: { parent: 'baylock - controller' },
        beforeEnter: admin_guard
      },

      {
        path: "baylock/mainboard",
        name: "Mainboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/wheellock/Mainboard.vue'),
        meta: { parent: 'baylock' },
        beforeEnter: admin_guard
      },

      {
        path: "baylock/baylock",
        name: "BayLock",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/wheellock/WheelLock.vue'),
        meta: { parent: 'baylock' },
        beforeEnter: admin_guard
      },

      {
        path: "finance/parkingtokens/parkingtokensmenu",
        name: "Parking Tokens Menu",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/ParkingTokensMenu.vue'),
        meta: { parent: 'finance - parking tokens' },
        beforeEnter: admin_guard
      },

      {
        path: "finance/parkingtokens/deductparkingtokens",
        name: "Deduct Parking Tokens",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/DeductParkingTokens.vue'),
        meta: { parent: 'finance - parking tokens' },
        beforeEnter: admin_guard
      },

      {
        path: "finance/parkingtokens/freeparkingtokens",
        name: "Free Parking Tokens",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/FreeParkingTokens.vue'),
        meta: { parent: 'finance - parking tokens' },
        beforeEnter: admin_guard
      },

      {
        path: "finance/setting/productservicesfees",
        name: "Product Services Fees",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/ProductServicesFees.vue'),
        meta: { parent: 'finance - setting' },
        beforeEnter: admin_guard
      },

      {
        path: "finance/setting/operatorremittancefinance",
        name: "Operator Remittance Finance",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/OperatorRemittanceFinance.vue'),
        meta: { parent: 'finance - setting' },
        beforeEnter: admin_guard
      },

      {
        path: "finance/setting/binfinitepaytid",
        name: "BInfinite Pay TID",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/BInfinitePayTID.vue'),
        meta: { parent: 'finance - setting' },
        beforeEnter: admin_guard
      },

      {
        path: "finance/setting/duitnow",
        name: "Duit Now",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/DuitNow.vue'),
        meta: { parent: 'finance - setting' },
        beforeEnter: admin_guard
      },

      {
        path: "finance/paymentgateway/tng",
        name: "Touch N Go e-Wallet",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/TNGPaymentGateway.vue'),
        meta: { parent: 'finance - payment gateway' },
        beforeEnter: admin_guard
      },

      {
        path: "finance/paymentgateway/tngqr",
        name: "Touch N Go QR",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/TNGQR.vue'),
        meta: { parent: 'finance - payment gateway' },
        beforeEnter: admin_guard
      },
      {
        path: "finance/paymentgateway/duitnow",
        name: "Duit Now",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "NotFound" */ '../components/finance/DuitNowPaymentGateway.vue'),
        meta: { parent: 'finance - payment gateway' },
        beforeEnter: admin_guard
      },
      {
        path: "finance/lhdn/settings",
        name: "Tax Setting",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "EInvoice Setup" */ '../components/lhdn/Setups.vue'),
        meta: { parent: 'finance - einvoice' },
        beforeEnter: admin_guard
      },
      {
        path: "finance/lhdn/trxn",
        name: "Tax Transaction",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "EInvoice Setup" */ '../components/lhdn/Transaction.vue'),
        meta: { parent: 'finance - einvoice' },
        beforeEnter: admin_guard
      },
      {
        path: "finance/lhdn/submission",
        name: "Submission",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "EInvoice Setup" */ '../components/lhdn/Submission.vue'),
        meta: { parent: 'finance - einvoice' },
        beforeEnter: admin_guard
      },
      {
        path: "finance/lhdn/submitted/doc",
        name: "Consolidated Bills",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "EInvoice Setup" */ '../components/lhdn/Document.vue'),
        meta: { parent: 'finance - einvoice' },
        beforeEnter: admin_guard
      },
      {
        path: "finance/lhdn/einvoice",
        name: "E-Invoice Documents",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "EInvoice Setup" */ '../components/lhdn/EInvoiceDocuments.vue'),
        meta: { parent: 'finance - einvoice' },
        beforeEnter: admin_guard
      },
    ]
  },

  {
    path: "/register",
    name: "register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/Register.vue'),
  },

  {
    path: "/changepassword",
    name: "changepassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/ChangePassword.vue'),
  },

  {
    path: "/resetpassword",
    name: "resetpassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/ResetPassword.vue'),
  },

  {
    path: "/404",
    alias: "*",
    name: "notFound",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "NotFound" */ '../components/NotFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
