/*
AP000 - SUCCESS
AP001 - INTERNAL ERROR
AP002 - PARAMETER/DATA ERROR
AT000 - UNAUTHORIZED
RD001 - SQL ERROR 1
RD002 - SQL ERROR 2
*/

export const ERR = { HANDLE : function(rq) {
    let message = '';

    if (rq == 'AP000'){ message = "Success"; }
    if (rq == 'AP001'){ message = "Internal Error"; }
    if (rq == 'AP002'){ message = "Parameter Error"; }
    if (rq == 'AT000'){ message = "Unauthorized"; }
    if (rq == 'RD001'){ message = "SQL Error 1"; }
    if (rq == 'RD002'){ message = "Error"; }
    if (rq == 'AP104'){ message = "Error"; }
    if (rq == 'AP108'){ message = "Error"; }
    if (rq == 'AP303'){ message = "Error"; }
    if (rq == 'AP708'){ message = "Proceed"}

    return message;
  }
}