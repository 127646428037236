<template>
  <v-container fill-height="fill-height" style="opacity: .91">
        <v-layout align-center="align-center" justify-center="justify-center">
                <v-fade-transition mode="out-in">

          <v-flex class="login-form text-xs-center"> 
            <div class="display-1 mb-3 white--text">
                <v-row dense>
                    <v-col cols="2">
                        <img style="max-width: 100%; height: auto" src="../assets/logo.png" />
                    </v-col>
                    <v-col cols="10" style="align-self: center">
                        <img style="max-width: 100%; height: auto" src="../assets/snatch_word_yellow.png" />
                    </v-col>
                </v-row>
            Welcome to Snatch Admin
            </div>
            <v-card light="light">
              <v-card-text>
                <div class="subheading">
                  <template>Log in</template>
                </div>
                <v-form ref="loginForm" action="#" @submit.prevent="validateBeforeSubmit" lazy-validation>
                  <v-text-field required :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Please enter an email address']" v-model="user.name" light="light" prepend-icon="fa fa-user" label="Email" type="text"></v-text-field>
                  <v-text-field :append-icon="user.show ? 'mdi-eye' : 'mdi-eye-off'" :type="user.show ? 'text' : 'password'" @click:append="user.show = !user.show"
                  required :rules="[v => !!v || 'Password is required']" v-model="user.password" light="light" prepend-icon="fa fa-lock" label="Password"></v-text-field>
                  <v-checkbox v-if="options.isLoggingIn" v-model="options.shouldStayLoggedIn" light="light" label="Stay logged in?" hide-details="hide-details"></v-checkbox>
                  <v-btn tile color="primary" block="block" type="submit" :loading="loading" :disabled="loading" class="mt-2">
                        Sign In
                        <template v-slot:loader>
                            <span>Loading...</span>
                        </template>
                  </v-btn>
                  <!--
                  <v-row>
                      <v-col cols="12" md="8"><span>Don't have an account? Register <a href="#"><u><router-link to="/register">here</router-link></u></a>.</span></v-col>
                      <v-col cols="12" md="4" class="text-md-right"><span><a href="#" @click="ex.modal = !ex.modal"><u>Forgotten Password</u></a></span></v-col>
                  </v-row>         
                  -->         
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
                </v-fade-transition>
          <v-footer app="app">
      <v-flex class="caption">Copyright By Snatch Park Bizsolutions Sdn Bhd © 2021</v-flex>
    </v-footer>
        </v-layout>
        <v-dialog
        v-model="dialog"
        persistent
        width="300"
        >
        <v-card
            color="primary"
            dark
        >
            <v-card-text>
            Logging In...
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
        </v-dialog>
        <v-snackbar
        v-model="toaster"
        :color="toasterColor"
        top
        >
        {{ toastertxt }}
        </v-snackbar>
            <requestemail :dialog="ex.modal" @closed="onClosed" :content="ex.content"></requestemail>
      </v-container>
</template>

<script>
import timer from '../assets/js/sleeper';
import requestemail from '../components/modals/RequestEmail';
import {ERR} from '../assets/js/errhandle.js';
import * as AppStorage from '../assets/js/storage';

export default {
    name: 'login',

    data(){
        return {
            options: {
                isLoggingIn: '',
                shouldStayLoggedIn: true,
            },

            user: {
                name: '',
                password: '',
                show: false
            },

            loading: false,
            dialog: false,
            toaster: false,
            toastertxt: '',
            toasterColor: 'red',
            ex: {
                modal: false,
                content: ''
            }
        }
    },

    components: {
        requestemail
    },

    created(){
        //this.test1();
    },

    methods: {
        /*
        test1(){
                if (!window.Notification) {
        console.log('Browser does not support notifications.');
    } else {
        // check if permission is already granted
        if (Notification.permission === 'granted') {
            // show notification here
            var notify = new Notification('From Snatch Park', {
                body: 'How are you doing?',
                icon: 'https://img.icons8.com/ios/50/000000/parking.png',
            });
        } else {
            // request permission from user
            Notification.requestPermission().then(function (p) {
                if (p === 'granted') {
                    // show notification here
                    var notify = new Notification('Hi there!', {
                        body: 'How are you doing?',
                        icon: 'https://bit.ly/2DYqRrh',
                    });
                } else {
                    console.log('User blocked notifications.');
                }
            }).catch(function (err) {
                console.error(err);
            });
        }
    }
        },
        */
        async userLogin(){
            this.loading = true
            this.dialog = true

            try{
                let response = await this.$store.dispatch("userLogin", {
                                    username: this.user.name,
                                    password: this.user.password
                                    })

                await timer.sleep(100);
                if (response.data.code == 'AP000')
                {
                    this.$router.push({ name: "Select System" });
                    AppStorage.set("user", this.user.name);
                    //this.$router.push({ name: "Select System" });
                    this.loading = false;
                    this.dialog = false;
                }

                else if (response.data.code === 'AP104')
                {
                    this.loading = false;
                    this.dialog = false;
                    this.toaster = true;
                    this.toastertxt = `${ERR.HANDLE(response.data.code)}: Email does not exist.`;
                }

                else if (response.data.code === 'AP108')
                {
                    this.loading = false;
                    this.dialog = false;
                    this.toaster = true;
                    this.toastertxt = `${ERR.HANDLE(response.data.code)}: Invalid account password. Please try again.`;
                }

                else
                {
                    this.loading = false;
                    this.dialog = false;
                    this.toaster = true;
                    this.toastertxt = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                this.loading = false;
                this.dialog = false;
            }
        },

        onClosed(value){
            if (value == false){
                this.ex.modal = value;
            }
        },

        validateBeforeSubmit() {
            if (this.$refs.loginForm.validate()) {
                this.userLogin();
            }
        }
    }
}
</script>

<style scoped lang="scss">
.login-form{
    max-width: 500px !important;
}
</style>