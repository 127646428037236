<template>
  <v-app id="inspire">
    

    <v-app-bar
      app
      clipped-right
      color="#3D348B"
      dark
      dense
      collapse-on-scroll
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{$route.name.charAt(0).toUpperCase() + $route.name.slice(1)}} | Snatch</v-toolbar-title>
      <v-spacer />
      <v-menu tile open-on-click bottom offset-y z-index="999" nudge-right="100" nudge-bottom="15">
        <template v-slot:activator="{ on }">
                            <v-btn
                            text
                            x-small
                            icon
                            v-on="on"
                            >
                            <v-icon small color="white">fas fa-user</v-icon>
                            </v-btn>
        </template>
        <v-list dense>
          <div v-for="(item, index) in system" :key="index">
          <v-list-item v-bind:style="item.ProfileTRSOC.replaceAll('|', '').replace('#', ',') == localstorage_perm ? { 'background-color' : '#3D348B'} : ''">
            <v-card @click="retoken(item.ProfileTRSOC)" flat width="300" style="border-radius: unset" hover class="hover-card">
              <v-card-title class="py-0"><p class="text-body-2 font-weight-bold py-0">{{item.ProfileType_Name}}</p></v-card-title>
              <v-card-text>
                <div class="text--primary">
                  <v-icon x-small>fa fa-star</v-icon> {{item.ProfileRole_Name}}
                </div>
                <div class="text--primary">
                  <ul>
                    <li v-if="item.SystemIDCheckList.length > 1">All</li>
                    <li v-else v-for="(itm, idx) in item.SystemIDCheckList" :key="idx">{{itm.SystemName}}</li>
                  </ul>
                </div>
                <!--<span class="text-caption">Last Login: 29/9/2020</span> -->
              </v-card-text>
            </v-card>
          </v-list-item>
          <v-divider v-if="index !== (system.length - 1)" class="my-2"></v-divider>
          </div>
        </v-list>

      </v-menu>
      <!--
      <v-btn icon x-small>
        <v-icon>fa fa-user</v-icon>
      </v-btn>
      -->
      <v-btn link text tile disabled>
        <h5>Welcome {{user}}</h5>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      color="#191716"
    >
      <v-list dense id="drawer-list">
        <v-list-item>
            <v-list-item-content>
              <v-list-item-title><v-img height="40"
              contain
              aspect-ratio="1" src="../assets/snatch_word_yellow.png"></v-img></v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <div v-for="(item, index) in nav" :key="index">
        <v-list-item @click="cleartemp(item.name)" v-if="!item.hasChild && item.permission" :to="item.url" v-bind:class="{ active: item.url == $route.path ? 'v-item--active' : '' }">
          <v-list-item-action>
            <v-icon small>{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="!item.hasChild && index !== nav.length - 1"></v-divider>
<div v-if="item.hasChild && item.permission">
        <v-list-group
        :group="item.url"
        color="white"
        active-class="parent-color"
        eager
        :value="$route.path.includes(item.url) ? true : false"
        no-action>
        <template v-slot:activator>
          <v-list-item-action>
            <v-icon small>{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-divider></v-divider>
        <div v-for="(child, idx) in item.children" :key="idx">
          <v-list-item @click="cleartemp" v-if="!child.hasChild && child.permission" :to="child.url" v-bind:class="{ active: child.url == $route.path ? 'v-item--active' : '' }">
            <v-list-item-action class="non-subgroup-child-icon">
              <v-icon x-small>{{child.icon}}</v-icon>
            </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{child.name}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        

          <div v-if="child.hasChild && child.permission">
            <v-list-group
            :group="child.url"
            color="white"
            active-class="parent-color"
            sub-group
            eager
            no-action>
            <template v-slot:activator>
              
              <v-list-item-content>
                <v-list-item-title>{{child.name}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-divider></v-divider>
            <div v-for="(child2, idx2) in child.children" :key="idx2">
            <v-list-item @click="cleartemp" v-if="!child2.hasChild && child2.permission" :to="child2.url" v-bind:class="{ active: child2.url == $route.path ? 'v-item--active' : '' }">
              <v-list-item-action>
                <v-icon x-small>{{child2.icon}}</v-icon>
              </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{child2.name}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </div>
          </v-list-group>
          </div>
        </div>
      </v-list-group>
      <v-divider></v-divider>
</div>
      </div>
      <v-divider></v-divider>
      </v-list>
      <!--
      <template v-slot:append>
        <div class="pa-2">
          <v-btn tile @click="logout" block color="error"><v-icon small left>fa fa-power-off</v-icon> Logout</v-btn>
        </div>
      </template>
      -->
    </v-navigation-drawer>

      <v-container fluid>
        <v-row
          justify="center"
          align="center"
        >
          <v-col cols="12">
            <transition name="slide" mode="out-in">
            <router-view :key="componentKey" v-if="check" />
            </transition>
          </v-col>
        </v-row>
      </v-container>

    

    <v-footer
      app
      color="#3D348B"
      class="white--text"
    >
      <span class="caption">Copyright By Snatch Park Bizsolutions Sdn Bhd © 2021</span>
      <v-spacer />
    </v-footer>
  </v-app>
</template>

<script>
import {nav} from '@/nav/nav'
import * as AppStorage from '../assets/js/storage';

  export default {
    name: 'Home',
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
      drawerRight: null,
      right: false,
      left: false,
      nav: '',
      componentKey: 0,
      user: AppStorage.get('user'),
      access: [],
      check: false,
      system: null,
      localstorage_perm: null,
      isMobile: false,
    }),

    async created() {
      //this.checkToken();
      this.nav = await nav.retrieve();
      this.nav.push({name: 'Logout', url: '/login', permission: true, icon: 'fa fa-power-off'});
      await this.checkPerm();
      this.system = this.$store.getters.getSystem;
      this.localstorage_perm = AppStorage.get('perm');
    },

    beforeDestroy () {
      if (typeof window === 'undefined') return

      window.removeEventListener('resize', this.onResize, { passive: true })
    },

    mounted(){
        this.onResize();
        window.addEventListener('resize', this.onResize, { passive: true })
        //this.test1();
    },

    methods: {
      cleartemp(name){
        this.componentKey += 1;
        AppStorage.set('filter', JSON.stringify({system: '', operator: '', carpark: '', ratecard: '', controller: '', displaystatus: null}));

        if(name.toLowerCase() == 'logout'){
          this.$store.dispatch('userLogout',{
                router: this.$router
          });
        }
      },

      logout() {
        this.$store.dispatch('userLogout',{
                router: this.$router
          });
      },

      async retoken(profiletrsoc){
            this.loading = true
            
            try{
                let response = await this.$store.dispatch("userRetoken", { profiletrsoc: profiletrsoc})
                if (response.data.code == 'AP000'){
                  this.componentKey += 1;
                  this.$router.replace({ name: "Home" });
                  location.reload();
                }

                else
                {
                    this.loading = false;
                    this.toaster = true;
                    this.toastertxt = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch (error){
                this.loading = false;
                this.dialog = false;
            }
      },

      async checkToken(){
            let token = AppStorage.get('jwtToken')
            try{
                let response = await this.$store.dispatch("checkToken", {
                                token: token
                              })
            }

            catch(err){}
      },

      async checkPerm(){
        let items = await this.getApiPath();
        let api_length = items.length;
        let responsenew = await this.$store.dispatch("viewAccess", {});
        for (let i=0; i<api_length; i++){
          if (responsenew.data.code === 'AP000'){
            let filterurls = responsenew.data.record.find((item) => {
              return item.Path == items[i].url;
            });

            if (filterurls !== undefined){
              let itemsasstring = filterurls.TypeRole;
              let itemsasarray = itemsasstring.split(', ');
              let itemsasarraywithoutbrackets = itemsasarray.map(i => i.replace(/[\[\]']+/g,''));
              
              let gettyperole = AppStorage.get('perm').split(",").slice(0,2).join("#");
              let checkifvalid = itemsasarraywithoutbrackets.find(item => item == gettyperole);

              if (checkifvalid){
                this.access.push({name: items[i].name, url: items[i].url, perm: true});
              }

              else{
                this.access.push({name: items[i].name, url: items[i].url, perm: false});
              }
            }

            else{
              this.access.push({name: items[i].name, url: items[i].url, perm: false});
            }
          }
          /*
          let response = await this.$store.dispatch("checkaccess", {path: items[i].url});
          if (response.data.code === 'AP000'){
            this.access.push({name: items[i].name, url: items[i].url, perm: true});
            //await this.$store.dispatch("checkaccess2", {name: items[i].name, url: items[i].url, perm: true})
          }

          else{
            this.access.push({name: items[i].name, url: items[i].url, perm: false});
            //await this.$store.dispatch("checkaccess2", {name: items[i].name, url: items[i].url, perm: true})
          }
          */
        }

        await this.$store.dispatch("checkaccess2", {access: this.access});
        this.check = true;
      },

      async getApiPath(){
        let arr = [
          {name: 'country_add', url: process.env.VUE_APP_COUNTRY_ADD},
          {name: 'country_delete', url: process.env.VUE_APP_COUNTRY_DELETE},
          {name: 'country_update', url: process.env.VUE_APP_COUNTRY_UPDATE},

          {name: 'state_add', url: process.env.VUE_APP_STATE_ADD},
          {name: 'state_delete', url: process.env.VUE_APP_STATE_DELETE},
          {name: 'state_update', url: process.env.VUE_APP_STATE_UPDATE},

          {name: 'city_add', url: process.env.VUE_APP_CITY_ADD},
          {name: 'city_delete', url: process.env.VUE_APP_CITY_DELETE},
          {name: 'city_update', url: process.env.VUE_APP_CITY_UPDATE},

          {name: 'postcode_add', url: process.env.VUE_APP_POSTCODE_ADD},
          {name: 'postcode_update', url: process.env.VUE_APP_POSTCODE_UPDATE},

          {name: 'system_add', url: process.env.VUE_APP_SYSTEM_ADD},
          {name: 'system_delete', url: process.env.VUE_APP_SYSTEM_DELETE},
          {name: 'system_update', url: process.env.VUE_APP_SYSTEM_UPDATE},

          {name: 'operator_add', url: process.env.VUE_APP_OPERATOR_ADD},
          {name: 'operator_delete', url: process.env.VUE_APP_OPERATOR_DELETE},
          {name: 'operator_update', url: process.env.VUE_APP_OPERATOR_UPDATE},

          {name: 'carpark_add', url: process.env.VUE_APP_CARPARK_ADD},
          {name: 'carpark_update', url: process.env.VUE_APP_CARPARK_UPDATE},
          {name: 'carpark_header', url: process.env.VUE_APP_CARPARK_HEADER},
          {name: 'carpark_footer', url: process.env.VUE_APP_CARPARK_FOOTER},
          {name: 'carpark_logo', url: process.env.VUE_APP_CARPARK_LOGO},
          {name: 'carpark_general', url: process.env.VUE_APP_CARPARK_GENERAL},
          {name: 'carpark_mapping', url: process.env.VUE_APP_CARPARK_MAPPING},
          {name: 'carpark_webpayment_create', url: process.env.VUE_APP_CARPARK_ADD_WEB_PAYMENT},
          {name: 'carpark_webpayment_update', url: process.env.VUE_APP_CARPARK_UPDATE_WEB_PAYMENT},

          {name: 'create_fmc', url: process.env.VUE_APP_FMC_CREATE},
          {name: 'downloadqr_fmc', url: process.env.VUE_APP_FMC_DOWNLOAD},
          {name: 'fmc_update', url: process.env.VUE_APP_FMC_UPDATE},
          {name: 'fmc_delete', url: process.env.VUE_APP_FMC_DELETE},
          {name: 'fmc_viewdetails', url: process.env.VUE_APP_FMC_VIEWDETAILS},


          {name: 'carpark_serviceusersadd', url: process.env.VUE_APP_SERVICEUSER_ADD},
          {name: 'carpark_specialrateadd', url: process.env.VUE_APP_SPECIALRATE_ADD},
          {name: 'carpark_specialrateupdate', url: process.env.VUE_APP_SPECIALRATE_UPDATE},
          {name: 'carpark_specialrate_viewdetails', url: process.env.VUE_APP_SPECIALRATE_VIEWDETAILS},

          {name: 'user_add', url: process.env.VUE_APP_USERS_ADDUSERANDPROFILE},
          {name: 'user_updateprofile', url: process.env.VUE_APP_USERS_UPDATEUSERANDPROFILE},
          {name: 'user_resetpassword', url: process.env.VUE_APP_USERS_RESETUSERPASSWORD},
          {name: 'user_removeprofile', url: process.env.VUE_APP_USERS_REMOVEUSERANDPROFILE},

          {name: 'admin_update_permission', url: process.env.VUE_APP_ADMIN_UPDATE_PERMISSION},

          {name: 'customer_add_profile', url: process.env.VUE_APP_CUSTOMER_ADD_PROFILE},
          {name: 'customer_remove_profile', url: process.env.VUE_APP_CUSTOMER_REMOVE_PROFILE},

          {name: 'bay_add', url: process.env.VUE_APP_CARPARKBAYS_ADD},
          {name: 'bay_delete', url: process.env.VUE_APP_CARPARKBAYS_DELETE},
          {name: 'bay_update', url: process.env.VUE_APP_CARPARKBAYS_UPDATE},

          {name: 'create_mobile', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_CREATE},
          {name: 'viewdetails_mobile', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_VIEWDETAILS},
          {name: 'update_mobilestatus', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_UPDATESTATUS},
          {name: 'view_pin', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_VIEWPIN},
          {name: 'view_ms_carplatesnatchp', url: process.env.VUE_APP_SUBSCRIBER_CARPLATESNATCHP},
          {name: 'create_transfertokens', url: process.env.VUE_APP_SUBSCRIBER_CREATETRANSFERTOKENS},

          {name: 'update_ms_allstatus', url: process.env.VUE_APP_SUBSCRIBER_ALLSTATUS},
          {name: 'viewdetails_ms_all', url: process.env.VUE_APP_SUBSCRIBER_VIEWDETAILS},
          {name: 'view_ms_allpin', url: process.env.VUE_APP_SUBSCRIBER_ALLPIN},
          {name: 'view_ms_carplateall', url: process.env.VUE_APP_SUBSCRIBER_CARPLATEALL},

          {name: 'update_ms_tngqrstatus', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_TNGQRSTATUS},
          {name: 'viewdetails_ms_tngqr', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_TNGQRVIEWDETAILS},

          {name: 'update_ms_bpstatus', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_BPSTATUS},
          {name: 'viewdetails_ms_bp', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_BPVIEWDETAILS},

          {name: 'update_ms_cpstatus', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_CPSTATUS},
          {name: 'viewdetails_ms_cp', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_CPVIEWDETAILS},

          {name: 'viewdetails_ms_vehicle', url: process.env.VUE_APP_SUBSCRIBER_VEHICLE_VIEWDETAILS},

          {name: 'create_corporate', url: process.env.VUE_APP_SUBSCRIBER_CORPORATE_CREATECORPORATE},
          {name: 'update_corporatestatus', url: process.env.VUE_APP_SUBSCRIBER_CORPORATE_UPDATECORPORATESTATUS},
          {name: 'viewdetails_corporate', url: process.env.VUE_APP_SUBSCRIBER_CORPORATE_VIEWCORPORATEDETAILS},

          {name: 'update_seasonstatus', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_UPDATESEASONSTATUS},
          {name: 'viewdetails_season', url: process.env.VUE_APP_SUBSCRIBER_MOBILE_VIEWSEASONDETAILS},

          {name: 'create_parkingentry', url: process.env.VUE_APP_SUBSCRIBER_PARKINGENTRY_CREATE},
          {name: 'create_parkingentryentry', url: process.env.VUE_APP_SUBSCRIBER_PARKINGENTRY_CREATEENTRY},
          {name: 'viewdetails_parkingentry', url: process.env.VUE_APP_SUBSCRIBER_PARKINGENTRY_VIEWDETAILS},
          
          {name: 'create_lpr_exit', url: process.env.VUE_APP_SUBSCRIBER_PARKINGENTRY_LPR_EXIT},
          {name: 'create_lpr_entry', url: process.env.VUE_APP_SUBSCRIBER_PARKINGENTRY_LPR_MANUAL_ENTRY},
          {name: 'create_parkingentry_lpr', url: process.env.VUE_APP_SUBSCRIBER_PARKINGENTRY_LPR_CREATE},
          {name: 'create_parkingentryentry_lpr', url: process.env.VUE_APP_SUBSCRIBER_PARKINGENTRY_LPR_CREATEENTRY},

          {name: 'create_baylockmanualexit', url: process.env.VUE_APP_SUBSCRIBER_BAYLOCKME_CREATE},
          {name: 'viewdetails_baylockparkingentry', url: process.env.VUE_APP_SUBSCRIBER_BAYLOCKPARKING_VIEWDETAILS},

          {name: 'businesscpo_usersadd', url: process.env.VUE_APP_BUSINESSCPO_USERS_ADD},
          {name: 'businesscpo_usersdelete', url: process.env.VUE_APP_BUSINESSCPO_USERS_DELETE},
          {name: 'businesscpo_usersupdate', url: process.env.VUE_APP_BUSINESSCPO_USERS_UPDATE},
          {name: 'businesscpo_usersreset', url: process.env.VUE_APP_BUSINESSCPO_USERS_RESET},

          {name: 'create_carparkrate', url: process.env.VUE_APP_CARPARKRATE_ADD},
          {name: 'update_carparkrate', url: process.env.VUE_APP_CARPARKRATE_UPDATE},
          {name: 'delete_carparkrate', url: process.env.VUE_APP_CARPARKRATE_DELETE},

          {name: 'controller_add', url: process.env.VUE_APP_BOOMGATE_CONTROLLER_ADD},
          {name: 'controller_reset', url: process.env.VUE_APP_BOOMGATE_CONTROLLER_RESET},
          {name: 'controller_viewdetails', url: process.env.VUE_APP_BOOMGATE_CONTROLLER_VIEWDETAILS},
          {name: 'controller_update', url: process.env.VUE_APP_BOOMGATE_CONTROLLER_UPDATE},
          {name: 'controller_updatecarparkdetails', url: process.env.VUE_APP_BOOMGATE_CONTROLLER_UPDATECPD},

          {name: 'gate_add', url: process.env.VUE_APP_BOOMGATE_GATE_ADD},
          {name: 'gate_reset', url: process.env.VUE_APP_BOOMGATE_GATE_RESET},
          {name: 'gate_viewdetails', url: process.env.VUE_APP_BOOMGATE_GATE_VIEWDETAILS},
          {name: 'gate_update', url: process.env.VUE_APP_BOOMGATE_GATE_UPDATE},
          {name: 'gate_updateisentry', url: process.env.VUE_APP_BOOMGATE_GATE_UPDATEISENTRY},
          {name: 'gate_updatecontroller', url: process.env.VUE_APP_BOOMGATE_GATE_UPDATECONTROLLER},

          {name: 'wlcontroller_add', url: process.env.VUE_APP_WHEELLOCK_CONTROLLER_ADD},
          {name: 'wlcontroller_reset', url: process.env.VUE_APP_WHEELLOCK_CONTROLLER_RESET},
          {name: 'wlcontroller_viewdetails', url: process.env.VUE_APP_WHEELLOCK_CONTROLLER_VIEWDETAILS},
          {name: 'wlcontroller_update', url: process.env.VUE_APP_WHEELLOCK_CONTROLLER_UPDATE},
          {name: 'wlcontroller_updatecarparkdetails', url: process.env.VUE_APP_WHEELLOCK_CONTROLLER_UPDATECPD},
          {name: 'wlcontroller_action', url: process.env.VUE_APP_WHEELLOCK_CONTROLLER_ACTION},

          {name: 'wlmainboard_add', url: process.env.VUE_APP_WHEELLOCK_MAINBOARD_ADD},
          {name: 'wlmainboard_viewdetails', url: process.env.VUE_APP_WHEELLOCK_MAINBOARD_VIEWDETAILS},
          {name: 'wlmainboard_update', url: process.env.VUE_APP_WHEELLOCK_MAINBOARD_UPDATE},
          {name: 'wlmainboard_updatecontrollerdetails', url: process.env.VUE_APP_WHEELLOCK_MAINBOARD_UPDATECPD},

          {name: 'wlwheellock_add', url: process.env.VUE_APP_WHEELLOCK_WHEELLOCK_ADD},
          {name: 'wlwheellock_reset', url: process.env.VUE_APP_WHEELLOCK_WHEELLOCK_RESET},
          {name: 'wlwheellock_viewdetails', url: process.env.VUE_APP_WHEELLOCK_WHEELLOCK_VIEWDETAILS},
          {name: 'wlwheellock_update', url: process.env.VUE_APP_WHEELLOCK_WHEELLOCK_UPDATE},
          {name: 'wlwheellock_updatemainboarddetails', url: process.env.VUE_APP_WHEELLOCK_WHEELLOCK_UPDATECPD},
          {name: 'wlwheellock_action', url: process.env.VUE_APP_WHEELLOCK_WHEELLOCK_ACTION},
          {name: 'wlwheellock_manual_trigger', url: process.env.VUE_APP_WHEELLOCK_WHEELLOCK_MANUAL_TRIGGER},

          {name: 'valuepass_add', url: process.env.VUE_APP_VALUEPASS_ADD},
          {name: 'valuepass_update', url: process.env.VUE_APP_VALUEPASS_UPDATE},

          {name: 'reviewreasons_add', url: process.env.VUE_APP_REVIEWREASONS_ADD},
          {name: 'reviewreasons_update', url: process.env.VUE_APP_REVIEWREASONS_UPDATE},

          {name: 'merchantrewardslots_add', url: process.env.VUE_APP_MERCHANTREWARDSLOTS_ADD},
          {name: 'merchantrewardslots_update', url: process.env.VUE_APP_MERCHANTREWARDSLOTS_UPDATE},

          {name: 'merchantrewards_add', url: process.env.VUE_APP_MERCHANTREWARDS_ADD},
          {name: 'merchantrewards_update', url: process.env.VUE_APP_MERCHANTREWARDS_UPDATE},

          {name: 'ptm_add', url: process.env.VUE_APP_FINANCEPT_PTM_ADD},
          {name: 'ptm_update', url: process.env.VUE_APP_FINANCEPT_PTM_UPDATE},
          {name: 'ptm_delete', url: process.env.VUE_APP_FINANCEPT_PTM_DELETE},

          {name: 'mc_add', url: process.env.VUE_APP_MESSAGE_MC_ADD},
          {name: 'mc_update', url: process.env.VUE_APP_MESSAGE_MC_UPDATE},
          {name: 'mc_viewdetails', url: process.env.VUE_APP_MESSAGE_MC_VIEWDETAILS},
          {name: 'mc_delete', url: process.env.VUE_APP_MESSAGE_MC_DELETE},

          {name: 'n_viewdetails', url: process.env.VUE_APP_MESSAGE_N_VIEWDETAILS},
          {name: 'n_delete', url: process.env.VUE_APP_MESSAGE_N_DELETE},

          {name: 'i_viewdetails', url: process.env.VUE_APP_MESSAGE_I_VIEWDETAILS},
          {name: 'i_delete', url: process.env.VUE_APP_MESSAGE_I_DELETE},

          {name: 'wt_add', url: process.env.VUE_APP_MESSAGE_WHATSAPPTEMPLATE_ADD},
          {name: 'wt_update', url: process.env.VUE_APP_MESSAGE_WHATSAPPTEMPLATE_UPDATE},

          {name: 'whatsapp_chat', url: process.env.VUE_APP_MESSAGE_WHATSAPP_CHAT},
          {name: 'whatsapp_viewdetails', url: process.env.VUE_APP_MESSAGE_WHATSAPP_VIEWDETAILS},

          {name: 'orf_add', url: process.env.VUE_APP_FINANCES_ORF_ADD},
          {name: 'orf_update', url: process.env.VUE_APP_FINANCES_ORF_UPDATE},
          {name: 'orf_delete', url: process.env.VUE_APP_FINANCES_ORF_DELETE},

          {name: 'bpt_add', url: process.env.VUE_APP_FINANCES_BPT_ADD},
          {name: 'bpt_update', url: process.env.VUE_APP_FINANCES_BPT_UPDATE},

          {name: 'dn_add', url: process.env.VUE_APP_FINANCES_DN_ADD},
          {name: 'dn_update', url: process.env.VUE_APP_FINANCES_DN_UPDATE},
          {name: 'dn_updatemainqrid', url: process.env.VUE_APP_FINANCES_DN_UPDATEMAINQRID},
          {name: 'dn_delete', url: process.env.VUE_APP_FINANCES_DN_DELETE},

          {name: 'fpt_add', url: process.env.VUE_APP_FINANCEPT_FPT_ADD},

          {name: 'dpt_add', url: process.env.VUE_APP_FINANCEPT_DPT_ADD},

          {name: 'customerprofiletype_add', url: process.env.VUE_APP_CUSTOMER_PROFILE_TYPE_ADD},
          {name: 'customerprofiletype_update', url: process.env.VUE_APP_CUSTOMER_PROFILE_TYPE_UPDATE},

          {name: 'customerprofilerole_add', url: process.env.VUE_APP_CUSTOMER_PROFILE_ROLE_ADD},
          {name: 'customerprofilerole_update', url: process.env.VUE_APP_CUSTOMER_PROFILE_ROLE_UPDATE},

          {name: 'rolepermissioncustomer_add', url: process.env.VUE_APP_CUSTOMER_ROLEPERMISSION_ADD},
          {name: 'rolepermissioncustomer_update', url: process.env.VUE_APP_CUSTOMER_ROLEPERMISSION_UPDATE},

          {name: 'calendar_add', url: process.env.VUE_APP_CALENDAR_ADD},
          {name: 'calendar_update', url: process.env.VUE_APP_CALENDAR_UPDATE},
          {name: 'calendar_delete', url: process.env.VUE_APP_CALENDAR_DELETE},

          {name: 'category_add', url: process.env.VUE_APP_CATEGORY_ADD},
          {name: 'category_update', url: process.env.VUE_APP_CATEGORY_UPDATE},
          {name: 'category_delete', url: process.env.VUE_APP_CATEGORY_DELETE},

          {name: 'issue_add', url: process.env.VUE_APP_ISSUE_ADD},
          {name: 'issue_update', url: process.env.VUE_APP_ISSUE_UPDATE},
          {name: 'issue_delete', url: process.env.VUE_APP_ISSUE_DELETE},

          {name: 'profileadmintype_add', url: process.env.VUE_APP_PROFILE_TYPE_ADD},
          {name: 'profileadminrole_add', url: process.env.VUE_APP_PROFILE_ROLE_ADD},

          {name: 'rolepermission_add', url: process.env.VUE_APP_ROLEPERMISSION_ADD},
          {name: 'rolepermission_update', url: process.env.VUE_APP_ROLEPERMISSION_UPDATE},

          {name: 'tng_download', url: process.env.VUE_APP_FINANCEPG_TNG_DOWNLOAD},

          {name: 'create_transfervp', url: process.env.VUE_APP_VALUEPASSUNITS_TRANSFER},
          {name: 'lpr_update_plateno', url: process.env.VUE_APP_LPR_UPDATE_PLATENO},
          
          {name: 'lhdn_msic', url: process.env.VUE_APP_LHDN_MSIC},
          {name: 'lhdn_state', url: process.env.VUE_APP_LHDN_STATE},
          {name: 'lhdn_country', url: process.env.VUE_APP_LHDN_COUNTRY},
          {name: 'lhdn_identifier', url: process.env.VUE_APP_LHDN_IDENTIFIER},
          {name: 'lhdn_validate', url: process.env.VUE_APP_LHDN_VALIDATE},
          {name: 'lhdn_create', url: process.env.VUE_APP_LHDN_CREATE},
          {name: 'lhdn_update', url: process.env.VUE_APP_LHDN_UPDATE},
          {name: 'lhdn_delete', url: process.env.VUE_APP_LHDN_DELETE},
          {name: 'lhdn_supplier', url: process.env.VUE_APP_LHDN_SUPPLIER},
          
        ];

        return arr;
      },

      onResize () {
            this.isMobile = window.innerWidth < 1036
        },
    }
  }
</script>

<style lang="scss">
/*
#drawer-list.v-list .v-list-item--active{
  background-color: #4C566B !important;
  //opacity: .24;
}
*/

.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon{
  i{
    font-size: 10px;
  }
}

#drawer-list .v-list-group.v-list-group--active {
    background-color: #3D348B !important;
}

.v-application--is-ltr .v-list-group--sub-group .v-list-group__items .v-list-item{
  padding-left: 55px !important;
  //padding-left: 40px !important;
}

.v-application--is-ltr .v-list--dense .v-list-group--sub-group .v-list-group__header{
  padding-left: 32px !important;
}

.non-subgroup-child-icon{
  margin-right: 12px !important;
  margin-left: 22px !important;
}

/*
.fade-enter-active,
.fade-leave-active{
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to{
  opacity: 0;
}
*/

.slide-enter-active,
.slide-leave-active{
  transition: opacity 0.25s, transform 0.25s;
}

.slide-enter,
.slide-leave-to{
  opacity: 0;
  transform: translate(-15%);
}

#main-table td, #main-table th{
    //white-space: nowrap;
    border: .5px solid #dddddd;
}

#main-table th{
    //white-space: nowrap;
    vertical-align: top;
}

.v-data-table th {
  font-size: 11px;
}

.v-data-table td {
  font-size: 11px;
}
</style>