import Vue from 'vue';
import Vuetify from 'vuetify';
//import '../assets/scss/style.scss';
//import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#3A3335',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        maingrey: '#58595b',
        blacksnatch: '#3A3335'
      },

      dark: {
        primary: '#2196F3',
        secondary: '#424242',
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        blacksnatch: '#3A3335'
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});