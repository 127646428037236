<template>
    <v-container fluid fill-height="fill-height">
        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-row class="justify-center flex-column align-center">
            <v-col md="4" class="text-center">
                <img
                src="../assets/snatch_word_yellow.png"
                width="350"
                style="text-align: center"
                >
                <h1 class="white--text text-body-1 font-weight-thin">Welcome to Snatch Admin</h1>
            </v-col>
            <v-col md="3">
                <div v-if="items.length > 0">
                <h2 class="white--text text-overline">PLEASE CHOOSE PROFILE TO PROCEED</h2>
                <v-card v-for="(item, index) in items" :key="index" class="mx-auto mb-2"
                :outlined="false" style="border-radius: unset">
                <v-hover v-slot:default="{ hover }">
                    <div style="position: relative">
                    <v-card-title style="justify-content: center; background-color: #3A3335; color: white; padding-top: 5px; padding-bottom: 5px">{{item.ProfileType_Name}}</v-card-title>
                    <v-card-text style="padding-bottom: 30px">
                        <v-row>
                            <!--
                            <v-col md="12">
                                Type: {{item.Type}}
                            </v-col>
                            <v-col md="12">
                                Role: {{item.Role}}
                            </v-col>
                            -->
                            <!--
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>fa fa-users</v-icon>
                                </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Type</v-list-item-title>
                                <v-list-item-subtitle>{{item.ProfileType_Name}}</v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                            -->

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon small>fa fa-user-tag</v-icon>
                                </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Role</v-list-item-title>
                                <v-list-item-subtitle>{{item.ProfileRole_Name}}</v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon small>fa fa-cogs</v-icon>
                                </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>System</v-list-item-title>
                                <v-list-item-subtitle v-if="item.SystemIDCheckList.length > 1">
                                    <span>All</span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else v-for="(itm, idx) in item.SystemIDCheckList" :key="idx">
                                    <span>{{itm.SystemName}}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                        </v-row>
                    </v-card-text>
                    <!-- <v-expand-transition @click="ttt()" :key="item.ID"> -->
                        <div style="position: absolute; bottom: 0; width: 100%">
                        <transition name="slide" mode="out-in">
                        <div
                            v-if="hover"
                            class="px-2 transition-fast-in-fast-out blacksnatch v-card--reveal white--text"
                            style="height: 100%; cursor: pointer;"
                            @click.prevent="retoken(item)"
                        >
                        <h3 class="white--text text-overline"> Go to profile </h3>
                        </div>
                        </transition>
                        </div>
                    <!-- </v-expand-transition> -->
                    </div>
                    </v-hover>
                </v-card>
                </div>
                <div v-else>
                    <h2 class="white--text text-overline">You have no profile therefore unable to proceed further. Please contact support to assist in providing a profile.</h2>
                    <v-btn block tile color="primary" @click="logout()">Log Out</v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Select System',

    data(){
        return{
            items: [],
            loading: false,
        }
    },

    created(){
        this.listSystems();
    },

    methods: {
        async listSystems(){
            this.loading = true;
            this.items = [];
            let response = await this.$store.dispatch('getSystems', {systemname: ''})
            if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.count;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                }

                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.loading = false;
                //this.$vuetify.goTo(0);
            }
        },

        async retoken(item){
            this.loading = true
            
            try{
                let response = await this.$store.dispatch("userRetoken", { profiletrsoc: item.ProfileTRSOC})
                if (response.data.code == 'AP000'){
                    this.$router.push({ name: "Home" });
                }

                else
                {
                    this.loading = false;
                    this.toaster = true;
                    this.toastertxt = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch (error){
                this.loading = false;
                this.dialog = false;
            }
        },

        logout() {
            this.$store.dispatch('userLogout',{
                    router: this.$router
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active{
  transition: opacity 0.25s, transform 0.25s;
}

.slide-enter,
.slide-leave-to{
  opacity: 0;
  transform: translateY(10%);
}
</style>