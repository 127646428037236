<template>
  <v-app class="mainbackgroundcol">
  <!-- <v-app :style="{backgroundImage: 'url('+bgImages+')'}" class="mainbackgroundcol"> -->
    <v-main>
      <router-view />
    </v-main>
    <v-snackbar
        v-model="toaster"
        :color="toasterColor"
        top
        >
        {{ toastertxt }}
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from 'axios'

export default {

  data() {
    return {
      toaster: false,
      toastertxt: '',
      toasterColor: 'red',
      bgImages: require("./assets/Artboard-Copy-9-1-769x1024.jpg")
    }
  },
    
    created() {
        axios.interceptors.response.use((response) => {
            if(response.data.code === 'AT000') {
              console.log(response.config.url);
              if (!response.config.url.includes("checkaccess") /* && !response.config.url.includes("config_") */ && !response.config.url.includes("generatepdf")){
                this.$store.dispatch('userLogout',{
                        router: this.$router
                  });
                    this.toaster = true;
                    this.toastertxt = "Token expired. Please login again";
              }
            }
            return response;
        }, (error) => {
            if (error.response && error.response.data) {
                return Promise.reject(error.response.data);
            }
            return Promise.reject(error.message);
        });
    }
}
</script>

<style lang="scss">
@import './assets/scss/style';
//.parent-color{
 //@extend .lightgrey;
//}
</style>