export default {
    sleeper(ms) {
        return function(x) {
            return new Promise(resolve => setTimeout(() => resolve(x), ms));
        };
    },

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
}