<template>
  <v-row justify="center">
    <v-dialog v-model="dialogLocal" max-width="350px">
        <v-card>
            <v-card-title class="justify-center">
            <span class="headline">{{content.headline}}</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <v-chip :color="content.chipColor" x-large style="border-radius: 50% !important">
                            <v-icon x-large :color="content.iconColor">{{content.icon}}</v-icon>
                        </v-chip>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <h2>{{content.maintext}}</h2>
                        <small>{{content.smalltext}}</small>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
            <v-btn small dark color="red darken-1" @click="$emit('closed', false)">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ['dialog', 'content'],

    data() {
        return {
            dialogLocal: false
        }
    },

    

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false)
            this.$emit('closed', false);
        },
    }
  }
</script>