import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from 'vue-router'
import {urlreq} from "../assets/js/urlreq.js";
import {jwt} from "../assets/js/decodejwt";
import * as AppStorage from '../assets/js/storage';
import * as moment from "moment";

Vue.use(router);

Vue.use(Vuex);

export const wsutil = new Vuex.Store({
    state : {
        jwtToken : AppStorage.get("jwtToken") || null,
        rfhToken : AppStorage.get("rfhToken") || null,
        apiBaseUrl : process.env.VUE_APP_DEVAPI_ENDPOINT,
        APICONFIGBASEURL : "https://config.snatchpark.com/",
        curAct : "",
        temp: "",
        userProfile : {},
        tableRow: [],
        accounts: [],
        perm: AppStorage.get('perm') || null,
        users: [],
        userscustomer: [],
        access: [],
        system: [],
        items: [],
        types: [],
        typescustomer: []
    },
    getters : {
        getJwt : state =>{
            return state.jwtToken;
        },

        getPerm : state =>{
            return state.perm;
        },

        getAccounts (state){
          return state.accounts;
        },

        getUsers (state){
            let items = JSON.parse(AppStorage.get('users'));
            state.users = items;
            return state.users;
        },

        getUsersCustomer (state){
            let items = JSON.parse(AppStorage.get('userscustomer'));
            state.userscustomer = items;
            return state.userscustomer;
        },

        getTypes (state){
            let items = JSON.parse(AppStorage.get('types'));
            state.types = items;
            return state.types;
        },

        getTypesCustomer (state){
            let items = JSON.parse(AppStorage.get('typescustomer'));
            state.typescustomer = items;
            return state.typescustomer;
        },

        getAccess(state){
            return state.access;
        },

        getSystem(state){
            let items = JSON.parse(AppStorage.get('system'));
            state.system = items;
            return state.system;
        },

        getItems(state){
            return state.items;
        },
    },
    mutations : {
      storeToken(state, token) {
        //store new jwt and refresh token
        let decodedjwt = jwt.parse(token);
        let all = decodedjwt.profiletr + ',' + decodedjwt.profilesoc;
        let allreplaced = all.replaceAll('|', '');
        allreplaced.replace(',', '#');
        AppStorage.set("perm", allreplaced);
        AppStorage.set("jwtToken", token);
        state.perm = allreplaced;
        state.jwtToken = token;
      },
      clearToken(state) {
        AppStorage.remove("jwtToken");
        AppStorage.remove("user");
        AppStorage.remove("users"); //for the role page
        AppStorage.remove("types"); //for the role page
        AppStorage.remove("userscustomer");
        AppStorage.remove("typescustomer");
        AppStorage.remove("perm");
        AppStorage.remove("system");
        AppStorage.remove('filter');
        state.perm = null;
        state.jwtToken = null;
      },
      getAccount(state, accounts) {
        state.accounts = accounts
      },
      setUsers(state, users){
        AppStorage.set("users", JSON.stringify(users));
        state.users = users;
      },
      setUsersCustomer(state, users){
        AppStorage.set("userscustomer", JSON.stringify(users));
        state.userscustomer = users;
      },
      setTypes(state, users){
        AppStorage.set("types", JSON.stringify(users));
        state.types = users;
      },
      setTypesCustomer(state, users){
        AppStorage.set("typescustomer", JSON.stringify(users));
        state.typescustomer = users;
      },
      setAccess(state, access){
        state.access = access;
      },
      setSystem(state, system){
        AppStorage.set("system", JSON.stringify(system));
        state.system = system;
      },
      setTempItems(state, items){
        state.items = items;
      },
    },
    actions : {
        userLogout(context, {router}){
            //this.$router.replace({ name: "Login" });
            context.commit('clearToken');
            router.push({ name: "login" });
        },
        userLogin(context, credentials){
          axios.defaults.headers.post['Content-Type'] = 'application/json';

            let rq = {"email": credentials.username,"password": credentials.password};

            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/admin/login/email', JSON.stringify(rq) )
                .then(response => {
                    if (response.data.code === 'AP000'){
                        //Store new token and call again the api
                        context.commit('storeToken', response.data.token);
                    }
                    else{
                        context.commit('clearToken');
                    }
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        userRetoken(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
  
              let rq = { "profiletrsoc": credentials.profiletrsoc };
  
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/adminprofile/my/retoken', JSON.stringify(rq), headers)
                  .then(response => {
                    if (response.data.code === 'AP000'){
                        //Store new token and call again the api
                        context.commit('storeToken', response.data.token);
                    }
                    else{
                        context.commit('clearToken');
                    }
                    resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

        userRegister(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
  
              let rq = { "name": credentials.name, "email": credentials.email };
  
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/register/email', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

        userUpdateAccount(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

                let rq = { "adminid": Number(credentials.adminid), "name": credentials.name, "activestatus": credentials.activestatus };

                return new Promise((resolve, reject) => {
                    axios.post(wsutil.state.apiBaseUrl + 'api/admin/update/account', JSON.stringify(rq), headers)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function(error){
                        reject(error);
                    });
                });
        },

          userReset(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = { "email": credentials.email };
  
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/resetpassword/email', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

          userResetCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = { "email": credentials.email };
  
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/customer/resetpassword/email', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

          resetPassword(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            let rq = {
                "email": credentials.username,
                "verifykey": credentials.verifykey,
                "password": credentials.password,
                "confirmpassword": credentials.confirmpassword
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/resetpassword/email/reset', JSON.stringify(rq))
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

          checkToken(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
  
              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/checktoken', {})
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

          checkCustomerExist(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "email": credentials.email
            }
  
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/view/customer/checkexist', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

          verifyEmail(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            let rq = {
                "email": credentials.email,
                "verifykey": credentials.verifykey
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/resetpassword/email/verify', JSON.stringify(rq))
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

          createPassword(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            let rq = {
                "username": credentials.username,
                "verifykey": credentials.verifykey,
                "password": credentials.password,
                "confirmpassword": credentials.confirmpassword
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/register/web/createpassword', JSON.stringify(rq))
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

          userCustomerRegister(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
  
              let rq = { "email": credentials.email };
  
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/customer/register/email', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

        getSystems(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/adminprofile/my/selection'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  context.commit('setSystem', response.data.record)
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getUsers(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "resultpage": 1,
                "rowresultsperpage": null
            }

          return new Promise((resolve, reject) => {

            let userUrl = 'api/view/admin/list/all'

              axios.post(wsutil.state.apiBaseUrl + userUrl, JSON.stringify(rq), headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAdminsTypeRole(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/profile/admin/view/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAllType(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/master/adminprofile/type/view/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAllRole(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/master/adminprofile/role/view/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        createProfile(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "userid": credentials.userid,
                "type": credentials.type,
                "role": credentials.role,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/profile/admin/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },

        updateProfile(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "profileid": credentials.profileid,
                "userid": credentials.userid,
                "type": credentials.type,
                "role": credentials.role,
            }
            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/profile/admin/update', JSON.stringify(rq), headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        updateProfileCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "profileid": credentials.profileid,
                "loginemailid": credentials.loginemailid,
                "operatorusername": credentials.operatorusername,
                "userid": credentials.userid,
                "type": credentials.type,
                "role": credentials.role,
                "operator": credentials.operator,
                "carpark": credentials.carpark
            }
            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/profile/customer/update', JSON.stringify(rq), headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        updateProfileCustomerName(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "operatoraccountid": credentials.operatoraccountid,
                "operatorusername": credentials.operatorusername,
            }
            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/admin/customer/operatoraccount/updatedetail', JSON.stringify(rq), headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        deleteProfile(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "profileid": credentials.profileid,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/profile/admin/remove', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getAllSystem(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "inputsearch": credentials.search,
            }

          return new Promise((resolve, reject) => {

            let userUrl = 'api/config_system/view/search'

              axios.post(wsutil.state.apiBaseUrl + userUrl, rq, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAllSystem2(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/config_system/view/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAllOperator(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "inputsearch": credentials.search,
            }

          return new Promise((resolve, reject) => {

            let userUrl = 'api/config_operator/view/search'

              axios.post(wsutil.state.apiBaseUrl + userUrl, rq, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAllOperator2(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/config_operator/view/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAllCarpark(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "searchinput": credentials.search == "" ? null : credentials.search,
                "operatorid": null,
                "systemid": null,
                "carparkid": null,
                "carparkmappingid": null,
                "carparkname": null,
                "carparklocation": null,
                "carparkbillingcompanyname": null,
                "carparkoperationstartdate": null,
                "carparkoperationenddate": null,
                "resultpage": null,
                "sortingcolumn": "CreatedDateTime"
            }

          return new Promise((resolve, reject) => {

            let userUrl = 'api/config_carpark/view/search'

              axios.post(wsutil.state.apiBaseUrl + userUrl, rq, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAllCarpark2(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/config_carpark/view/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getAdminsSOC(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/profile/admin_soc/view/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        createSOC(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "profileid": Number(credentials.profileid),
                "userid": Number(credentials.userid),
                "systemid": credentials.systemid,
                "operatorid": credentials.operatorid,
                "carparkid": credentials.carparkid,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/profile/admin_soc/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateSOC(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "profileadminid": Number(credentials.profileadminid),
                "profileid": Number(credentials.profileid),
                "userid": Number(credentials.userid),
                "systemid": credentials.systemid,
                "operatorid": credentials.operatorid,
                "carparkid": credentials.carparkid,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/profile/admin_soc/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteSOC(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "profileadminid": Number(credentials.profileadminid),
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/profile/admin_soc/remove', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listCountry(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "inputsearch": credentials.search,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_country/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listCountryAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/config_location_country/view/all', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createCountry(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "countryname": credentials.countryname,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_country/register/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteCountry(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_country/delete/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateCountry(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "newcountrycode": credentials.newcountrycode,
                "countryname": credentials.countryname,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_country/update/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listState(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "inputsearch": credentials.search,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_state/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createState(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "statename": credentials.statename,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_state/register/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteState(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "statecode": credentials.statecode,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_state/delete/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateState(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "statecode": credentials.statecode,
                "statename": credentials.statename,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_state/update/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listCity(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "inputsearch": credentials.search,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_city/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listCityByCountryState(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "statecode": credentials.statecode
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_city/view/bycountrycodestatecode', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createCity(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "statecode": credentials.statecode,
                "cityname": credentials.cityname,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_city/register/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteCity(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "citycode": credentials.citycode,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_city/delete/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateCity(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "statecode": credentials.statecode,
                "citycode": credentials.citycode,
                "cityname": credentials.cityname,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_city/update/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listPostcode(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "inputsearch": credentials.search,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_postcode/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createPostcode(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "statecode": credentials.statecode,
                "citycode": credentials.citycode,
                "postcode": credentials.postcode,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_postcode/register/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updatePostcode(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "statecode": credentials.statecode,
                "citycode": credentials.citycode,
                "postcode": credentials.postcode,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_location_postcode/update/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listSystem(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "inputsearch": credentials.search == "" ? null : credentials.search,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_system/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createSystem(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "systemref": credentials.systemref,
                "systemname": credentials.systemname,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_system/register/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateSystem(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "systemid": Number(credentials.systemid),
                "systemref": credentials.systemref == "" ? null : credentials.systemref,
                "systemname": credentials.systemname,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_system/update/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteSystem(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "systemid": Number(credentials.systemid),
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_system/delete/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listOperator(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "inputsearch": credentials.search,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_operator/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createOperator(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "systemid": Number(credentials.systemid),
                "operatorname": credentials.operatorname,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_operator/register/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteOperator(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "operatorid": Number(credentials.operatorid),
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_operator/delete/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateOperator(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "operatorid": credentials.operatorid,
                "operatorname": credentials.operatorname,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_operator/update/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listCarpark(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('resultpage') ? credentials.resultpage : "";

            let rq = {
                "searchinput": credentials.search == "" ? null : credentials.search,
                "systemid": credentials.systemid == "" ? null : credentials.systemid,
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": null,
                "carparkmappingid": null,
                "carparkname": null,
                "carparklocation": null,
                "carparkbillingcompanyname": null,
                "carparkoperationstartdate": null,
                "carparkoperationenddate": null,
                "resultpage": page == null || page == "" ? null : page,
                "sortingcolumn": credentials.sortingcolumn == "" ? null : credentials.sortingcolumn,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createCarpark(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "operatorid": Number(credentials.operatorid),
                "carparkname": credentials.carparkname,
                "carparklocation": credentials.carparklocation,
                "carparkdescription": credentials.carparkdescription,
                "carparklocationgpslatitude": credentials.carparklocationgpslatitude,
                "carparklocationgpslongitude": credentials.carparklocationgpslongitude,
                "carparkaddress1": credentials.carparkaddress1,
                "carparkaddress2": credentials.carparkaddress2,
                "carparkaddress3": credentials.carparkaddress3,
                "carparkaddresspostcode": credentials.carparkaddresspostcode,
                "carparkaddresscity": credentials.carparkaddresscity,
                "carparkaddressstate": credentials.carparkaddressstate,
                "carparkaddresscountry": credentials.carparkaddresscountry,
                "carparktelephoneno1": credentials.carparktelephoneno1,
                "carparktelephoneno2": credentials.carparktelephoneno2,
                "carparkfaxno": credentials.carparkfaxno,
                "carparkemail": credentials.carparkemail,
                "carparkweburl": credentials.carparkweburl,
                "carparkcontactpersonname": credentials.carparkcontactpersonname,
                "carparkcontactpersonemail": credentials.carparkcontactpersonemail,
                "carparksoscontact1": credentials.carparksoscontact1,
                "carparksoscontact2": credentials.carparksoscontact2,
                "carparkbillingcompanyname": credentials.carparkbillingcompanyname,
                "carparkbillingregno": credentials.carparkbillingregno,
                "carparkbillingsstno": credentials.carparkbillingsstno,
                "carparkbillingaddress1": credentials.carparkbillingaddress1,
                "carparkbillingaddress2": credentials.carparkbillingaddress2,
                "carparkbillingaddress3": credentials.carparkbillingaddress3,
                "carparkbillingaddresspostcode": credentials.carparkbillingaddresspostcode,
                "carparkbillingaddresscity": credentials.carparkbillingaddresscity,
                "carparkbillingaddressstate": credentials.carparkbillingaddressstate,
                "carparkbillingaddresscountry": credentials.carparkbillingaddresscountry,
                "carparkbillingtelephoneno": credentials.carparkbillingtelephoneno,
                "carparkbillingfaxno": credentials.carparkbillingfaxno,
                "carparkbillingemail": credentials.carparkbillingemail,
                "carparkbillingweburl": credentials.carparkbillingweburl,
                "carparkbillingtermsconditions": credentials.carparkbillingtermsconditions,
                "carparktotalbaycount": credentials.carparktotalbaycount,
                "carparktotalbayavailable": credentials.carparktotalbayavailable,
                "carparkoperationstarthour": credentials.carparkoperationstarthour,
                "carparkoperationendhour": credentials.carparkoperationendhour,
                "carparkoperationstartdate": credentials.carparkoperationstartdate,
                "carparkoperationenddate": credentials.carparkoperationenddate,
                "carparkcontracttype": credentials.carparkcontracttype,
                "carparklicenseapplicationcommencementdate": credentials.carparklicenseapplicationcommencementdate,
                "carparktaxoption": credentials.carparktaxoption,
                "carparktaxamount": credentials.carparktaxamount,
                "carparkoptions": credentials.carparkoptions,
                "carparkfeereactivation": credentials.carparkfeereactivation,
                "carparkfeelatepaymentpenalty": credentials.carparkfeelatepaymentpenalty,
                "carparkfeereinstatement": credentials.carparkfeereinstatement,
                "carparkpaymentmethod": credentials.carparkpaymentmethod,
                "carparklogoimage": credentials.carparklogoimage,
                "carparkheaderimage": credentials.carparkheaderimage,
                "carparkfooterimage": credentials.carparkfooterimage,
                "carparkgeneraldocumentimage": credentials.carparkgeneraldocumentimage,
                "carparkuserinputcode": credentials.carparkuserinputcode,
                "carparkactivestatus": credentials.carparkactivestatus,
                "carparkmobiledisplaystatus": credentials.carparkmobiledisplaystatus,
                "carparktype": credentials.carparktype,
                "carparkwheellockstatus": credentials.carparkwheellockstatus,
                "carparkmanagedbaycount": credentials.carparkmanagedbaycount == "0" || credentials.carparkmanagedbaycount == "" ? null : credentials.carparkmanagedbaycount,
                "carparkbayprefix": credentials.carparkbayprefix,
                "carparkwebpaymentlprurl": credentials.carparkwebpaymentlprurl,
                "carparkwebpaymentwlurl": credentials.carparkwebpaymentwlurl,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/register/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateCarpark(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "operatorid": Number(credentials.operatorid),
                "carparkname": credentials.carparkname,
                "carparklocation": credentials.carparklocation,
                "carparkdescription": credentials.carparkdescription,
                "carparklocationgpslatitude": credentials.carparklocationgpslatitude,
                "carparklocationgpslongitude": credentials.carparklocationgpslongitude,
                "carparkaddress1": credentials.carparkaddress1,
                "carparkaddress2": credentials.carparkaddress2,
                "carparkaddress3": credentials.carparkaddress3,
                "carparkaddresspostcode": credentials.carparkaddresspostcode,
                "carparkaddresscity": credentials.carparkaddresscity,
                "carparkaddressstate": credentials.carparkaddressstate,
                "carparkaddresscountry": credentials.carparkaddresscountry,
                "carparktelephoneno1": credentials.carparktelephoneno1,
                "carparktelephoneno2": credentials.carparktelephoneno2,
                "carparkfaxno": credentials.carparkfaxno,
                "carparkemail": credentials.carparkemail,
                "carparkweburl": credentials.carparkweburl,
                "carparkcontactpersonname": credentials.carparkcontactpersonname,
                "carparkcontactpersonemail": credentials.carparkcontactpersonemail,
                "carparksoscontact1": credentials.carparksoscontact1,
                "carparksoscontact2": credentials.carparksoscontact2,
                "carparkbillingcompanyname": credentials.carparkbillingcompanyname,
                "carparkbillingregno": credentials.carparkbillingregno,
                "carparkbillingsstno": credentials.carparkbillingsstno,
                "carparkbillingaddress1": credentials.carparkbillingaddress1,
                "carparkbillingaddress2": credentials.carparkbillingaddress2,
                "carparkbillingaddress3": credentials.carparkbillingaddress3,
                "carparkbillingaddresspostcode": credentials.carparkbillingaddresspostcode,
                "carparkbillingaddresscity": credentials.carparkbillingaddresscity,
                "carparkbillingaddressstate": credentials.carparkbillingaddressstate,
                "carparkbillingaddresscountry": credentials.carparkbillingaddresscountry,
                "carparkbillingtelephoneno": credentials.carparkbillingtelephoneno,
                "carparkbillingfaxno": credentials.carparkbillingfaxno,
                "carparkbillingemail": credentials.carparkbillingemail,
                "carparkbillingweburl": credentials.carparkbillingweburl,
                "carparkbillingtermsconditions": credentials.carparkbillingtermsconditions,
                "carparktotalbaycount": credentials.carparktotalbaycount,
                "carparktotalbayavailable": credentials.carparktotalbayavailable,
                "carparkoperationstarthour": credentials.carparkoperationstarthour,
                "carparkoperationendhour": credentials.carparkoperationendhour,
                "carparkoperationstartdate": credentials.carparkoperationstartdate,
                "carparkoperationenddate": credentials.carparkoperationenddate,
                "carparkcontracttype": credentials.carparkcontracttype,
                "carparklicenseapplicationcommencementdate": credentials.carparklicenseapplicationcommencementdate,
                "carparktaxoption": credentials.carparktaxoption,
                "carparktaxamount": credentials.carparktaxamount,
                "carparkoptions": credentials.carparkoptions,
                "carparkfeereactivation": credentials.carparkfeereactivation,
                "carparkfeelatepaymentpenalty": credentials.carparkfeelatepaymentpenalty,
                "carparkfeereinstatement": credentials.carparkfeereinstatement,
                "carparkpaymentmethod": credentials.carparkpaymentmethod,
                "carparklogoimage": credentials.carparklogoimage,
                "carparkheaderimage": credentials.carparkheaderimage,
                "carparkfooterimage": credentials.carparkfooterimage,
                "carparkgeneraldocumentimage": credentials.carparkgeneraldocumentimage,
                "carparkuserinputcode": credentials.carparkuserinputcode,
                "carparkactivestatus": credentials.carparkactivestatus,
                "carparkmobiledisplaystatus": credentials.carparkmobiledisplaystatus,
                "carparktype": credentials.carparktype,
                "carparkwheellockstatus": credentials.carparkwheellockstatus,
                "carparkmanagedbaycount": credentials.carparkmanagedbaycount == "0" || credentials.carparkmanagedbaycount == "" ? null : credentials.carparkmanagedbaycount,
                "carparkwebpaymentlprurl": credentials.carparkwebpaymentlprurl,
                "carparkwebpaymentwlurl": credentials.carparkwebpaymentwlurl,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/update/details/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateCarParkPrefix(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "bayprefix": credentials.bayprefix,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/update/bayprefix/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        viewAllCarParkBay(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
              headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('resultpage') ? credentials.resultpage : "";

            let systemid = "SystemID=" + (credentials.systemid || 'null');
            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "PageNo=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "RowsNo=" + 100 : "RowsNo=" + credentials.rowresultsperpage;
            let sort = "Sort=" + (credentials.sortingcolumn || null);

            let r = [systemid, operatorid, carparkid, searchinput, resultpage, rowresultsperpage, sort];

            let urlReq = urlreq.createReq(r);
    
              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/config_carparkbay/view/all' + urlReq, headers )
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        viewAllCarParkBayByCarParkID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
              headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
            }
    
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carparkbay/view/bycarparkid', JSON.stringify(rq), headers )
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        viewAllCarParkAvailableBayByCarParkID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
              headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
    
              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/config_carparkbay/view/wlavailablebay?CarParkID=' + credentials.carparkid, headers )
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateLogoImage(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "carparklogo": credentials.carparklogo,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/update/logoimage/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateHeaderImage(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "carparkheader": credentials.carparkheader,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/update/headerimage/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateFooterImage(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "carparkfooter": credentials.carparkfooter,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/update/footerimage/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateGeneralImage(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "carparkgeneraldocument": credentials.carparkgeneraldocument,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/update/generaldocumentimage/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listProfileType(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/master/adminprofile/type/view/all', headers)
                  .then(response => {
                      context.commit('setTypes', response.data.record);
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listProfileTypeCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/master/customerprofile/type/view/all', headers)
                  .then(response => {
                    context.commit('setTypesCustomer', response.data.record);
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createProfileType(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "typename": credentials.typename,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/adminprofile/type/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createProfileTypeCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "typename": credentials.typename,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/customerprofile/type/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listProfileRole(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/master/adminprofile/role/view/all', headers)
                  .then(response => {
                      context.commit('setUsers', response.data.record);
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        listProfileRoleCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/master/customerprofile/role/view/all', headers)
                  .then(response => {
                      context.commit('setUsersCustomer', response.data.record);
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createProfileRole(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "rolename": credentials.rolename,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/adminprofile/role/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createProfileRoleCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "rolename": credentials.rolename,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/customerprofile/role/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateMappingID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "carparkmappingid": Number(credentials.carparkmappingid)
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/update/mappingid/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        viewAccess(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
              headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
    
              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/accesspermission_admin/view/all', headers )
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        viewAccessCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
              headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
    
              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/accesspermission_customer/view/all', headers )
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateAccess(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
              headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
  
            let rq = {accesspermissionadmin: credentials.selected};
  
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/accesspermission_admin/update/item', rq, headers )
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateAccessCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
              headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
  
            let rq = {accesspermissioncustomer: credentials.selected};
  
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/accesspermission_customer/update/item', rq, headers )
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        checkaccess(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
              headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
  
            let rq = {path: credentials.path};
  
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/accesspermission_admin/checkaccess', rq, headers )
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        checkaccess2(context, credentials) {
            context.commit('setAccess', credentials.access);
            /*
            let aa = JSON.parse(JSON.stringify(credentials.b));
            let check = aa.find(item => {
                return item.name === credentials.a;
            });
            console.log('check', check);
        
            return check;
            */
        },

        addtempitems(context, credentials) {
            context.commit('setTempItems', credentials.items);
        },

        getCustomerTypeRole(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/profile/customer/view/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getCustomers(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/view/customer/list/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getCustomersOperatorAdmin(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('resultpage') ? credentials.resultpage : "";

            let rq = {type: 2, role: 3, resultpage: page, rowresultsperpage: 100};

          return new Promise((resolve, reject) => {

            let userUrl = 'api/view/customer/list/operator/typerole'

              axios.post(wsutil.state.apiBaseUrl + userUrl, rq, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getCustomersOperator(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('resultpage') ? credentials.resultpage : "";

            let rq = {searchinput: credentials.search, resultpage: page, "rowresultsperpage": credentials.rowresultsperpage === "" || credentials.rowresultsperpage === undefined ? 100 : credentials.rowresultsperpage};

          return new Promise((resolve, reject) => {

            let userUrl = 'api/view/customer/list/operator/all'

              axios.post(wsutil.state.apiBaseUrl + userUrl, rq, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        createProfileCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "loginemailid": credentials.loginemailid,
                "operatorusername": credentials.operatorusername,
                "type": Number(credentials.type),
                "role": Number(credentials.role),
                "operator": credentials.operator,
                "carpark": credentials.carpark,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/profile/customer/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteProfileCustomer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "profileid": credentials.profileid,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/profile/customer/remove', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getRateCard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

          return new Promise((resolve, reject) => {

            let userUrl = 'api/prate_ratecard/view/all'

              axios.get(wsutil.state.apiBaseUrl + userUrl, headers)
              .then(response => {
                  resolve(response);
              })
              .catch(function(error){
                  reject(error);
              });
          });
        },

        getSpecialRateCard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "ratecardid": credentials.ratecardid == "" ? null : credentials.ratecardid,
                "startdatetime": null,
                "enddatetime": null,
                "search": credentials.search == ""? null : credentials.search,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/specialratevalidation/list/available/byselection', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getRateCardByCarParkID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/prate_ratecard/view/bycarparkid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getRateCardByID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ratecardid": Number(Number(credentials.ratecardid)),
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/prate_ratecard/view/item/forupdate', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createRateCard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "ratecardjson": {
                    "name": credentials.name,
                    "cutoff": credentials.cutoff,
                    "entrygrace": credentials.entrygrace,
                    "exitgrace": credentials.exitgrace,
                    "activestatus": credentials.activestatus,
                    "mon": credentials.mon,
                    "tue": credentials.tue,
                    "wed": credentials.wed,
                    "thu": credentials.thu,
                    "fri": credentials.fri,
                    "sat": credentials.sat,
                    "sun": credentials.sun,
                    "phol": credentials.phol
                }
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/prate_ratecard/create/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateRateCard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ratecardid": Number(credentials.ratecardid),
                "ratecardjson": {
                    "name": credentials.name,
                    "cutoff": credentials.cutoff,
                    "entrygrace": credentials.entrygrace,
                    "exitgrace": credentials.exitgrace,
                    "activestatus": credentials.activestatus,
                },
                "carparkid": credentials.carparkid
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/prate_ratecard/update/main/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteRateCard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ratecardid": Number(credentials.ratecardid),
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/prate_ratecard/delete/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        chooseDefaultRateCard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ratecardid": Number(Number(credentials.ratecardid)),
                "carparkid": Number(credentials.carparkid)
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/prate_ratecard/update/chosendefaultratecardforcarpark', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateExistingRateCardSlot(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ratecardid": Number(credentials.ratecardid),
                "slotid": Number(credentials.slotid),
                "slotjson": {
                    "validstartdatetime": credentials.activationdate,
                    "validenddatetime": credentials.deactivationdate,
                    "start_hour": credentials.start_hour,
                    "end_hour": credentials.end_hour,
                    "rate_type": credentials.rate_type,
                    "rate": credentials.rate,
                    "max": credentials.max,
                    "continue": credentials.continue,
                    "subtime": credentials.subtime
                },
                "carparkid": credentials.carparkid
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/prate_ratecard/update/existing/slotid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        addNewRateCardSlot(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ratecardid": Number(credentials.ratecardid),
                "selectedday": credentials.selectedday,
                "slotjson": {
                    "validstartdatetime": credentials.activationdate,
                    "validenddatetime": credentials.deactivationdate,
                    "start_hour": credentials.start_hour,
                    "end_hour": credentials.end_hour,
                    "rate_type": credentials.rate_type,
                    "rate": credentials.rate,
                    "max": credentials.max,
                    "continue": credentials.continue,
                    "subtime": credentials.subtime
                }
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/prate_ratecard/update/new/slotid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        calculateRateCard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "ratecardid": Number(Number(credentials.ratecardid)),
                "starttime": credentials.starttime,
                "endtime": credentials.endtime,
                "holidaydate": null,
                "lastpaymentdatetime": null
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/prate_parkingpayment/calculate', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createBays(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "baynameid": credentials.baynameid,
                "baytype": credentials.baytype,
                "bayproducttype": credentials.bayproducttype,
                "baylatitude": credentials.baylatitude || null,
                "baylongitude": credentials.baylongitude || null,
                "bayno": credentials.bayno,
                "bayoperatorcode": credentials.bayoperatorcode || null
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carparkbay/update/item/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        editBays(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "carparkbayid": Number(credentials.carparkbayid),
                "baynameid": credentials.baynameid,
                "baytype": credentials.baytype,
                "bayproducttype": credentials.bayproducttype,
                "baylatitude": credentials.baylatitude || null,
                "baylongitude": credentials.baylongitude || null,
                "bayoperatorcode": credentials.bayoperatorcode || null,
                "bayno": credentials.bayno
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carparkbay/update/item/existing', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        removeBays(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": Number(credentials.carparkid),
                "carparkbayid": Number(credentials.carparkbayid)
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_carparkbay/delete/item', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createValuePass(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "CarParkID": Number(credentials.CarParkID),
                "ValuePassName": credentials.ValuePassName,
                "Description": credentials.Description,
                "TNC": credentials.TNC,
                "Image": credentials.Image,
                "PurchaseCodeApplicable": credentials.PurchaseCodeApplicable,
                "Quantity": Number(credentials.Quantity) == 0 ? "" : Number(credentials.Quantity),
                "MinPurchaseQuantity": Number(credentials.MinPurchaseQuantity) == 0 ? "" : Number(credentials.MinPurchaseQuantity),
                "MaxPurchaseQuantity": Number(credentials.MaxPurchaseQuantity) == 0 ? "" : Number(credentials.MaxPurchaseQuantity),
                "StartSellDate": credentials.StartSellDate,
                "EndSellDate": credentials.EndSellDate,
                "StartUseDate": credentials.StartUseDate,
                "EndUseDate": credentials.EndUseDate,
                "ValuePassCost": Number(credentials.ValuePassCost) == 0 ? "" : Number(credentials.ValuePassCost),
                "ValuePassType": credentials.ValuePassType,
                "MultipleEntriesPerValuePass": credentials.MultipleEntriesPerValuePass,
                "ValuePassRules": {
                    "NumberOfEntries": Number(credentials.NumberOfEntries) == 0 ? null : Number(credentials.NumberOfEntries),
                    "NumberOfParkingDays": Number(credentials.NumberOfParkingDays) == 0 ? null : Number(credentials.NumberOfParkingDays),
                    "AllowedParkingDurationHours": credentials.ValuePassType == 'time' ? null : Number(credentials.AllowedParkingDurationHours),
                    "AllowedEntryTimeStart": credentials.AllowedEntryTimeStart,
                    "AllowedEntryTimeEnd": credentials.AllowedEntryTimeEnd,
                    "AllowedExitTimeStart": credentials.AllowedExitTimeStart,
                    "AllowedExitTimeEnd": credentials.AllowedExitTimeEnd,
                    "AllowedEntryDates": credentials.AllowedEntryDates,
                    "AllowedDays": credentials.AllowedDays,
                },
                "MobileDisplayStatus": credentials.MobileDisplayStatus,
            }

            let rqforlog = {
                "CarParkID": Number(credentials.CarParkID),
                "ValuePassName": credentials.ValuePassName,
                "Description": credentials.Description,
                "TNC": credentials.TNC,
                "Image": "dummyforlog",
                "PurchaseCodeApplicable": credentials.PurchaseCodeApplicable,
                "Quantity": Number(credentials.Quantity) == 0 ? "" : Number(credentials.Quantity),
                "MinPurchaseQuantity": Number(credentials.MinPurchaseQuantity) == 0 ? "" : Number(credentials.MinPurchaseQuantity),
                "MaxPurchaseQuantity": Number(credentials.MaxPurchaseQuantity) == 0 ? "" : Number(credentials.MaxPurchaseQuantity),
                "StartSellDate": credentials.StartSellDate,
                "EndSellDate": credentials.EndSellDate,
                "StartUseDate": credentials.StartUseDate,
                "EndUseDate": credentials.EndUseDate,
                "ValuePassCost": Number(credentials.ValuePassCost) == 0 ? "" : Number(credentials.ValuePassCost),
                "ValuePassType": credentials.ValuePassType,
                "MultipleEntriesPerValuePass": credentials.MultipleEntriesPerValuePass,
                "ValuePassRules": {
                    "NumberOfEntries": Number(credentials.NumberOfEntries) == 0 ? null : Number(credentials.NumberOfEntries),
                    "NumberOfParkingDays": Number(credentials.NumberOfParkingDays) == 0 ? null : Number(credentials.NumberOfParkingDays),
                    "AllowedParkingDurationHours": credentials.ValuePassType == 'time' ? null : Number(credentials.AllowedParkingDurationHours),
                    "AllowedEntryTimeStart": credentials.AllowedEntryTimeStart,
                    "AllowedEntryTimeEnd": credentials.AllowedEntryTimeEnd,
                    "AllowedExitTimeStart": credentials.AllowedExitTimeStart,
                    "AllowedExitTimeEnd": credentials.AllowedExitTimeEnd,
                    "AllowedEntryDates": credentials.AllowedEntryDates,
                    "AllowedDays": credentials.AllowedDays,
                },
                "MobileDisplayStatus": credentials.MobileDisplayStatus,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/create', JSON.stringify(rq), headers)
                  .then(response => {
                      this.dispatch("sendLog", {message1: `Value Pass Create - ${credentials.ValuePassName}`, message2: `User: ${AppStorage.get('user')}\n\nStatus: ${response.data.code}\n\nFullMessage\n${JSON.stringify(rqforlog)}`, id: '-', moduleID: 46, level: 1})
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateValuePass(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ValuePassProductID": Number(credentials.ValuePassProductID),
                "CarParkID": Number(credentials.CarParkID),
                "ValuePassName": credentials.ValuePassName,
                "Description": credentials.Description,
                "TNC": credentials.TNC,
                //"Image": credentials.Image,
                "PurchaseCodeApplicable": credentials.PurchaseCodeApplicable,
                "AdditionalValuePassQuantity": Number(credentials.AdditionalValuePassQuantity) == 0 || Number(credentials.AdditionalValuePassQuantity) == '' ? null : Number(credentials.AdditionalValuePassQuantity),
                "MinPurchaseQuantity": Number(credentials.MinPurchaseQuantity) == 0 ? "" : Number(credentials.MinPurchaseQuantity),
                "MaxPurchaseQuantity": Number(credentials.MaxPurchaseQuantity) == 0 ? "" : Number(credentials.MaxPurchaseQuantity),
                "StartSellDate": credentials.StartSellDate,
                "EndSellDate": credentials.EndSellDate,
                "StartUseDate": credentials.StartUseDate,
                "EndUseDate": credentials.EndUseDate,
                "ValuePassCost": Number(credentials.ValuePassCost) == 0 ? "" : Number(credentials.ValuePassCost),
                "ValuePassType": credentials.ValuePassType,
                "MultipleEntriesPerValuePass": credentials.MultipleEntriesPerValuePass,
                "ValuePassRules": {
                    "NumberOfEntries": Number(credentials.NumberOfEntries) == 0 ? null : Number(credentials.NumberOfEntries),
                    "NumberOfParkingDays": Number(credentials.NumberOfParkingDays) == 0 ? null : Number(credentials.NumberOfParkingDays),
                    "AllowedParkingDurationHours": credentials.ValuePassType == 'time' ? null : Number(credentials.AllowedParkingDurationHours),
                    "AllowedEntryTimeStart": credentials.AllowedEntryTimeStart,
                    "AllowedEntryTimeEnd": credentials.AllowedEntryTimeEnd,
                    "AllowedExitTimeStart": credentials.AllowedExitTimeStart,
                    "AllowedExitTimeEnd": credentials.AllowedExitTimeEnd,
                    "AllowedEntryDates": credentials.AllowedEntryDates,
                    "AllowedDays": credentials.AllowedDays,
                },
                "MobileDisplayStatus": credentials.MobileDisplayStatus,
                "Active": credentials.Active,
	            "Delete": false
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/update', JSON.stringify(rq), headers)
                  .then(response => {
                    this.dispatch("sendLog", {message1: `Value Pass Update - ${credentials.ValuePassName}`, message2: `User: ${AppStorage.get('user')}\n\nStatus: ${response.data.code}\n\nFullMessage\n${JSON.stringify(rq)}`, id: '-', moduleID: 46, level: 1})

                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        generatePurchaseCode(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ValuePassProductID": credentials.ValuePassProductID,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/generatepurchasecode', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        addValuePassImage(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ValuePassProductID": credentials.ValuePassProductID,
                "Image": credentials.Image,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/addimage', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteValuePassImage(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ImageUrl": credentials.Image,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/deleteimage', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getValuePassList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "RowsPerPage": 100,
                "Page": page,
                "SearchInput": credentials.search == "" ? null : credentials.search,
                "OperatorID": credentials.operatorid === "" ? null : credentials.operatorid,
                "CarParkID": credentials.carparkid === "" ? null : credentials.carparkid,
                "ValuePassProductID": credentials.valuepassproductid == undefined ? null : credentials.valuepassproductid,
                "ValuePassGroupID": null,
                "ValuePassName": null,
                "PurchaseCodeRequired": null,
                "MobileDisplayStatus": credentials.mobiledisplaystatus == true ? 1 : credentials.mobiledisplaystatus == false ? 0 : credentials.mobiledisplaystatus,
                "Active": null,
                "Delete": null,
                "ValidSellDate": null,
                "ValidUseDate": null,
                "ValidSellingQuantityLeft": null
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getValuePassUnitsList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "resultpage": page,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "rowresultsperpage": 100
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vpunitpurchase/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getValuePassPurchaseTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "resultpage": page,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "rowresultsperpage": 100
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vppurchasebytransaction/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getUsedValuePassTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "resultpage": page,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "rowresultsperpage": 100
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vpunitutilization/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getValuePassOwnershipUnitsList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "mobileno": null,
                "valuepassproductid": null,
                "valuepassname": null,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": 100
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vpownershipbyvppassid/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getPurchasedMerchantRewardsTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginemailid": null,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "slotgroupid": null,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantreward/merchantrewardslotpurchase/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingWheelLockTransactionAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "Page=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/trxn/all' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingWheelLockTransactionApp(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "Page=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/trxn/mobile' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingWheelLockTransactionPole(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "Page=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/trxn/pole' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingWheelLockTransactionNoPayment(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "Page=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/trxn/parknrun' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getParkingWheelLockTransactionManualTrigger(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = credentials.operatorid ? "operatorID=" + (credentials.operatorid || 'null') : '';
            let carparkid = credentials.carparkid ? "carparkID=" + (credentials.carparkid || 'null') : '';
            let validatorid = credentials.validatorid ? "validatorID=" + (credentials.validatorid || 'null') : '';
            let searchinput = credentials.search ? "search=" + (credentials.search || 'null'): "";
            let resultpage = "page=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "rows=" + 100 : "rows=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid,validatorid, searchinput, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/wheellock/control/history' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCollectedPromoVouchersTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginemailid": null,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "slotgroupid": null,
                "slotid": null,
                "advertid": null,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantreward/merchantrewardcollectpromo/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getRedeemedPromoVouchersTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "slotgroupid": null,
                "slotid": null,
                "advertid": null,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? null : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantreward/merchantrewardredeempromo/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getValuePassOwnershipUnitsListByVPProductID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "mobileno": null,
                "valuepassproductid": credentials.ValuePassProductID,
                "valuepassname": null,
                "carparkid": null,
                "resultpage": 1,
                "rowresultsperpage": null
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vpownershipbyvpproductid/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getValuePassSold(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": null,
                "carparkid": null,
                "valuepassproductid": credentials.ValuePassProductID,
                "searchinput": null,
                "resultpage": 1,
                "rowresultsperpage": 100
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vppurchasebyvpproductid/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getValuePassPurchasedList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "valuepasstrxref": null,
                "amountgrandtotalfrom": null,
                "amountgrandtotalto": null,
                "transactioncreateddatetimefrom": null,
                "transactioncreateddatetimeto": null,
                "valuepasstrxstatus": null,
                "resultpage": page
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/transaction/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getValuePassTransferredUnitsList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "resultpage": page,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "rowresultsperpage": 100
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vpunittransfer/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getValuePassTNC(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/valuepass/list/item/tnc/' + credentials.valuepassproductid, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getMobileSubscriber(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginmobileid": null,
                "resultpage": page,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "rowresultsperpage": 100,
                "registrationType": credentials.registrationType || null
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/view/customer/list/subscriberdriver', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getMobileAccessPin(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "loginmobileid": credentials.loginmobileid,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/accesspin/getaccesspinbyloginmobileid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCarPlateAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "Page=" + page;
            let rowresultsperpage = "Rows=100";
            let r = [searchinput, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/carregno/view/all' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCarPlate(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/carregno/view/' + credentials.loginmobileid + '/item', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateMobileSubscriberStatus(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "loginemailid": credentials.loginemailid,
                "loginmobileid": credentials.loginmobileid,
                "lockaccountstatus": credentials.lockaccountstatus,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/customer/lockaccount', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createMobileSubscriberDriver(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "customermobileno": credentials.customermobileno,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/customer/createaccount/subscriberdriver', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createTransferTokens(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "senderLoginMobileID": credentials.senderLoginMobileID,
                "receiverLoginMobileID": credentials.receiverLoginMobileID,
                "paymentTokenAmt": credentials.paymentTokenAmt
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/transact/adminsendtoken', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createUserOperatorAccount(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "customeremail": credentials.customeremail,
                "operatorusername": credentials.operatorusername,
                "roleforoperator": credentials.roleforoperator,
                "operatorpermission": credentials.operatorpermission,
                "carparkpermission": credentials.carparkpermission
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/customer/createaccount/operator', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createServiceUserAccount(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "customeremail": credentials.customeremail,
                "customerusername": credentials.customerusername,
                "desiredpassword": credentials.desiredpassword,
                "operatorpermission": credentials.operatorpermission,
                "carparkpermission": credentials.carparkpermission
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/customer/createaccount/servicewebuser', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createUserCorporateAccount(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "customeremail": credentials.customeremail,
                "corporatename": credentials.corporatename,
                "picname": credentials.picname,
                "piccontact": credentials.piccontact
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/customer/createaccount/subscribercorporate', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getSeasonSubscriber(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "searchinput": credentials.search === "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/season/seasonaccess/getlist/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getSeasonSubscriberTable(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "searchinput": credentials.search === "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": 100,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/season/seasonaccess/getlist/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCasualSubscriberTable(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let searchinput = "searchinput=" + (credentials.search || 'null');
            let resultpage = "resultpage=" + page;
            let loginmobileid = "loginmobileid=null";
            let rowresultsperpage = "rowresultsperpage=100";
            let r = [searchinput, resultpage, loginmobileid, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/view/customer/list/subscriberdriveractive' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getWhatsAppMessageBody(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/admin/whatsapp/tmpl/' +credentials.ID+ '/item', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getMessageTemplate(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let twilio = "ApprovedByTwilio=" + (credentials.ApprovedByTwilio || null);
            let searchinput = "searchinput=" + (credentials.search || 'null');
            let rowresultsperpage = "rowresultsperpage=" + (credentials.rowresultsperpage || null);
            let r = [twilio, searchinput, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/admin/whatsapp/tmpl/listall' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createMessageTemplate(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "TemplateName": credentials.TemplateName,
                "TemplateText": credentials.TemplateText,
                "PlaceholderCount": Number(credentials.PlaceholderCount),
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/whatsapp/tmpl/create', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateMessageTemplate(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "TemplateText": credentials.TemplateText,
                "PlaceholderCount": Number(credentials.PlaceholderCount),
                "ApprovedByTwilio": credentials.ApprovedByTwilio
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/whatsapp/tmpl/' + credentials.ID + '/edit', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getWhatsApp(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let searchinput = "searchinput=" + (credentials.search || 'null');
            let resultpage = "resultpage=" + page;
            let statusID = "statusID=null";
            let ID = "ID=null";
            let rowresultsperpage = "rowresultsperpage=100";
            let sortingcolumn = "sortingcolumn=createdDate"
            let r = [searchinput, resultpage, statusID, ID, rowresultsperpage, sortingcolumn];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/admin/whatsapp/blast/listall' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getWhatsAppByID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/admin/whatsapp/blast/' + credentials.ID + '/item', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getWhatsAppChatByID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/admin/whatsapp/blast/' + credentials.ID + '/chat/listall', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        whatsAppChatReply(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "message": credentials.message,
                "image": credentials.image
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/whatsapp/blast/' + credentials.ID + '/chat/reply', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createBlastPushNotification(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "loginmobileids": credentials.loginmobileids,
                "title": credentials.title,
                "subtitle": credentials.subtitle,
                "message": credentials.message,
                "notificationcategoryid": credentials.notificationcategoryid,
                "url": credentials.url,
                "image": credentials.image
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/pushnotification/blast', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createBlastWhatsApp(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "phonenumbers": credentials.phonenumbers,
                "templateID": credentials.templateID,
                "placeholdersValues": credentials.placeholdersValues,
                "image": credentials.image,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/whatsapp/blast', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createBlastSMS(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "phonenumbers": credentials.phonenumbers,
                "message": credentials.message,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/sms/blast', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getSeasonSubscriberCorporate(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "searchinput": credentials.search === "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": 100
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/view/customer/list/subscribercorporate', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getSeasonSubscriberCorporateAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "loginemailid": null,
                "corporateaccountid": null,
                "searchinput": credentials.search === "" ? null : credentials.search,
                "resultpage": 1,
                "rowresultsperpage": null
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/view/customer/list/subscribercorporate', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateSeasonAccessStatus(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "seasonaccessid": credentials.seasonaccessid,
                "enabledstatus": credentials.enabledstatus,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/season/seasonaccess/updatestatus', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingEntry(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "resultpage": page,
                "searchinput": credentials.search,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
                "startdate":credentials.startdate == "" || credentials.startdate == undefined ? null : credentials.startdate,
                "enddate":credentials.enddate == "" || credentials.enddate == undefined ? null : credentials.enddate,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/ticket/view/inparking/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBayLockParkingEntry(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let claimbymobile = "ClaimedByMobile=null";
            let resultpage = "Page=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, claimbymobile, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/trxn/inparking' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBayLockEntryPaymentDetails(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/process/' + credentials.ParkingWheelLockID + '/updatewldetails', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getPaymentTrxnDetails(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "parkingentryid": credentials.parkingentryid
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/ticket/view/transactions/payment/byparkingentryid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBayLockManualExitPaymentTrxnDetails(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/trxn/payment/' + credentials.id + '/manualexit', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingTransactionAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "resultpage": page,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "searchinput": credentials.search === "" ? null : credentials.search,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/ticket/view/transactions/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getSpecialRateTransactionAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "page": page,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkID": credentials.carparkid === "" ? null : credentials.carparkid,
                "search": credentials.search === "" ? null : credentials.search,
                "rows": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
                "inparking": credentials.inparking,
                "startDate": null,
                "endDate": null
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/specialratevalidation/assignedtosubscriberdriver/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getManualParkingTransactionAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "resultpage": page,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "searchinput": credentials.search === "" ? null : credentials.search,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/ticket/view/transactions/manualexit/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getManualEntryParkingTransactionAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            const search= credentials.search != null && credentials.search != '' ? "plateno=" + credentials.search : '';
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            console.log(credentials);
            let r = [
                search,
                carparkid,
            ];
            let urlReq = urlreq.createReq(r);
            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parking/ticket/view/transactions/manualentry/all' + urlReq,
                //   JSON.stringify(rq),
                   headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBayLockManualParkingTransactionAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "PageNo=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "RowsNo=" + 100 : "RowsNo=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/trxn/manualexit' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getBayLockGracePeriodTransactionAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "Page=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/trxn/grace' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getPaymentTicketDetails(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "parkingentryid": credentials.parkingentryid
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/ticket/view/updateticketdetails', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createManualExit(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "parkingentryid": credentials.parkingentryid,
                "parkingtokenamountmyr": credentials.parkingtokenamountmyr,
                "valuepasspassid": credentials.valuepasspassid,
                "finalparkingamountmyr": credentials.finalparkingamountmyr,
                "exitgateid": credentials.exitgateid,
                "requestedticketexitdatetime": credentials.requestedticketexitdatetime,
                "categoryID": credentials.categoryID,
                "issueID": credentials.issueID,
                "reasonformanualexit": credentials.reasonformanualexit,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/ticket/update/manualexit', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        createManualLPRExit(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "entryid": credentials.parkingentryid,
                "amounttobededuct": credentials.parkingtokenamountmyr,
                "valuepass": credentials.valuepasspassid,
                "totalamountpaid": credentials.finalparkingamountmyr,
                "gateid": credentials.exitgateid,
                "exitdatetime": credentials.requestedticketexitdatetime,
                "categoryID": credentials.categoryID,
                "issueID": credentials.issueID,
                "reason": credentials.reasonformanualexit,
                "cardnumber": credentials.cardnumber,
                "encryptedcardnumber": credentials.encryptedcardnumber,
                "paymenttype": credentials.pmethod,
                "carplateno": credentials.carplateno
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/lpr/process/manualexit', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createBayLockParkingManualExit(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ParkingWheelLockID": credentials.parkingentryid,
                "parkingtokenamt": credentials.parkingtokenamountmyr,
                "paymentmethod": credentials.paymentmethod,
                "valuepasspassid": credentials.valuepasspassid,
                "finalamount": credentials.finalparkingamountmyr,
                "exitdatetime": credentials.requestedticketexitdatetime,
                "categoryID": credentials.categoryID,
                "issueID": credentials.issueID,
                "reason": credentials.reasonformanualexit,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingwheellock/process/manualexit', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getManualExitCategory(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${wsutil.state.jwtToken}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let id = "ID=" + (credentials.ID || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "Page=" + page;
            let rows = credentials.Rows == "" || credentials.Rows == undefined ? "Rows=" + 100 : "Rows=" + credentials.Rows;
            let r = [id, searchinput, resultpage, rows];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/master/manualexit/category/listall' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createCategory(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "CategoryName": credentials.CategoryName,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/manualexit/category/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteCategory(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/manualexit/category/' + credentials.ID + '/delete', {}, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateCategory(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                CategoryName: credentials.CategoryName
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/manualexit/category/' + credentials.ID + '/update', rq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getManualExitIssue(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${wsutil.state.jwtToken}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let id = "ID=" + (credentials.ID || 'null');
            let categoryid = "CategoryID=" + (credentials.CategoryID || 'null');
            let searchinput = "Search=" + (credentials.search || 'null');
            let resultpage = "Page=" + page;
            let rows = credentials.Rows == "" || credentials.Rows == undefined ? "Rows=" + 100 : "Rows=" + credentials.Rows;

            let r = [id, categoryid, searchinput, resultpage, rows];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/master/manualexit/issue/listall' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createIssue(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "CategoryID": credentials.CategoryID,
                "IssueName": credentials.IssueName,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/manualexit/issue/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteIssue(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/manualexit/issue/' + credentials.ID + '/delete', {}, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateIssue(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "CategoryID": credentials.CategoryID,
                "IssueName": credentials.IssueName
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/manualexit/issue/' + credentials.ID + '/update', rq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createManualEntry(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "loginmobileid": credentials.loginmobileid,
                "gateid": credentials.gateid,
                "requestedticketentrydatetime": credentials.requestedticketentrydatetime
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/ticket/update/manualentry', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getGateController(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "searchinput": credentials.search,
                "Enabled": null,
                "GateControllerType": null,
                "RowsPerPage": credentials.rowresultsperpage === "" || credentials.rowresultsperpage === undefined ? 100 : credentials.rowresultsperpage,
                "Page": page
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gatecontroller/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getWheelLockController(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "searchinput": credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage === "" || credentials.rowresultsperpage === undefined ? 100 : credentials.rowresultsperpage,
                "baytype": credentials.type
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellockcontroller/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createWheelLockController(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": credentials.carparkid,
                "wlcontrollerref": credentials.wlcontrollerref,
                "remark": credentials.remark,
                "type": credentials.type
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellockcontroller/create', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateWheelLockController(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "wlcontrollerid": credentials.GateControllerID,
                "carparkid": credentials.CarParkID,
                "wlcontrollerref": credentials.RefControllerID,
                "remark": credentials.Remark,
                "enabled": credentials.Enabled,
                "wlcontrollertoken": credentials.wlcontrollertoken || null,
                "poledesc": credentials.poledesc || null,
                "mbdesc": credentials.mbdesc || null,
                "type": credentials.type || null
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellockcontroller/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        resetWheelLockController(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "wlcontrollerid": credentials.wlcontrollerid,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellockcontroller/regenerateuuid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        syncWheelLockControllerTrxn(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellockcontroller/' + credentials.wlcontrollerid + '/manualsync', {}, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        resetSyncWheelLockControllerCPDelta(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellockcontroller/' + credentials.wlcontrollerid + '/resetdelta', {}, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBayLockControllerItem(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/wheellockcontroller/' + credentials.wlcontrollerid + '/item', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getWheelLockMainboard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "wlcontrollerid": credentials.controllerid || null,
                "searchinput": credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
                "baytype": credentials.type
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellockmainboard/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createWheelLockMainboard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "wlcontrollerid": credentials.wlcontrollerid,
                "wlmainboardref": credentials.wlmainboardref,
                "remark": credentials.remark || null,
                "type": credentials.type
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellockmainboard/create', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateWheelLockMainboard(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let poledesc = credentials.poledesc || null;
            poledesc = poledesc == "N/A" ? null : poledesc;

            let rq = {
                "wlcontrollerid": credentials.wlcontrollerid,
                "wlmainboardid": credentials.wlmainboardid,
                "wlmainboardref": credentials.wlmainboardref,
                "poledesc": poledesc,
                "remark": credentials.remark || null,
                "enabled": credentials.enabled,
                "type": credentials.type
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellockmainboard/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getWheelLock(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "wlcontrollerid": credentials.controllerid === "" ? null : credentials.controllerid,
                "wlmainboardid": credentials.mainboardid === "" ? null : credentials.mainboardid,
                "searchinput": credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
                "baytype": credentials.type
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellocklock/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createWheelLock(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "wlmainboardid": credentials.wlmainboardid,
                "wllockref": credentials.wllockref,
                "bayid": 0, //updated 28/4/2022
                "remark": credentials.remark
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellocklock/create', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateWheelLock(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "wllockid": credentials.wllockid,
                "wlmainboardid": credentials.wlmainboardid,
                "wllockref": credentials.wllockref,
                "wllocktoken": credentials.wllocktoken,
                "bayid": credentials.bayid,
                "remark": credentials.remark,
                "enabled": credentials.enabled,
                "type": credentials.type
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellocklock/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        resetWheelLock(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "wllockid": credentials.wllockid,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellocklock/regenerateuuid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getControllerOfflineTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "gatecontrollerid": credentials.gatecontrollerid == "" ? null : credentials.gatecontrollerid,
                "gateid": null,
                "loginmobileid": null,
                "trxcreateddatetimestart": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "trxcreateddatetimeend": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'CreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/offline/seasonparkingrecord/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getGateControllerByID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "gatecontrollerid": credentials.gatecontrollerid
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gatecontroller/view/bygatecontrollerid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createGateController(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "CarParkID": credentials.CarParkID,
                "GateControllerType": credentials.GateControllerType,
                "RefControllerID": credentials.RefControllerID
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gatecontroller/create', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateGateController(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "GateControllerID": credentials.GateControllerID,
                "CarParkID": credentials.CarParkID,
                "GateControllerType": credentials.GateControllerType,
                "RefControllerID": credentials.RefControllerID,
                "ControllerToken": credentials.ControllerToken,
                "PoleDesc": credentials.PoleDesc,
                "GateDesc": credentials.GateDesc,
                "Remark": credentials.Remark,
                "Enabled": credentials.Enabled,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gatecontroller/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        resetGateController(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "GateControllerID": credentials.GateControllerID,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gatecontroller/regenerateuuid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        resetGate(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "GateID": credentials.GateID,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gate/regenerateuuid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getGateControllerFORGATE_TEMP(context, credentials){ //to be removed once luqman returns carpark and operator on get gate api
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "OperatorID": null,
                "CarParkID": null,
                "Enabled": null,
                "GateControllerType": null,
                "RowsPerPage": null,
                "Page": 1
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gatecontroller/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getGate(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "searchinput": credentials.search === "" ? null : credentials.search,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "RowsPerPage": 100,
                "Page": page,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gate/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getGateDropDown(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "searchinput": credentials.search === "" ? null : credentials.search,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "RowsPerPage": 99999999,
                "Page": page,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gate/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getGateByID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "gateid": credentials.gateid
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gate/view/bygateid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateGate(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "GateID": credentials.GateID,
                "RefGateID": credentials.RefGateID,
                "GateToken": credentials.GateToken,
                "GateControllerID": credentials.GateControllerID,
                "GateType": credentials.GateType,
                "PoleDesc": credentials.PoleDesc,
                "Remark": credentials.Remark,
                "IsEntryGate": credentials.IsEntryGate,
                "Enabled": credentials.Enabled,
                "GateCareToken": credentials.GateCareToken
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gate/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createGate(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "RefGateID": credentials.RefGateID,
                //"GateToken": credentials.GateToken,
                "GateControllerID": credentials.GateControllerID,
                //"GateType": credentials.GateType,
                //"PoleDesc": credentials.PoleDesc,
                //"Remark": credentials.Remark,
                //"IsEntryGate": credentials.IsEntryGate,
                //"Enabled": credentials.Enabled,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/gate/create', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getServiceUsers(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('resultpage') ? credentials.resultpage : "";

            let rq = {
                "loginemailid": null,
                "type": 3,
                "role": 6,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/view/customer/list/typerole', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getMessageSMS(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('resultpage') ? credentials.resultpage : "";

            let rq = {
                "mobile": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sms/sent/blast', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getMessageCategory(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : null;

            let categoryid = "categoryid=" + (credentials.categoryid || 'null');
            let searchinput = "searchinput=" + (credentials.search || 'null');
            let resultpage = "resultpage=" + page;
            let sortingcolumn = "sortingcolumn=null";
            let notiTypeID = "notiTypeID=" + (credentials.notiTypeID || 'null');
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "rowresultsperpage=" + 100 : "rowresultsperpage=" + credentials.rowresultsperpage;
            let r = [searchinput, categoryid, resultpage, sortingcolumn, notiTypeID, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/master/pushnotification/category/listall' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createMessageCategory(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "notiTypeID": credentials.messagetype,
                "categoryname": credentials.messagecategory,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/pushnotification/category/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateMessageCategory(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "notiTypeID": credentials.messagetype,
                "categoryname": credentials.categoryname,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/pushnotification/category/'+ credentials.categoryid +'/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteMessageCategory(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/pushnotification/category/'+ credentials.categoryid +'/delete',{}, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getPushNotification(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : null;

            let notificationcategoryid = "notificationcategoryid=" + (credentials.notificationcategoryid || 'null');
            let notificationisread = "notificationisread=" + (credentials.notificationisread || 'null');
            let searchinput = "searchinput=" + (credentials.search || 'null');
            let resultpage = "resultpage=" + page;
            let sortingcolumn = "sortingcolumn=null";
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "rowresultsperpage=" + 100 : "rowresultsperpage=" + credentials.rowresultsperpage;
            let r = [searchinput, notificationcategoryid, notificationisread, resultpage, sortingcolumn, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/admin/pushnotification/notice/listall' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deletePushNotification(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let req = {
                "notificationids": [
                    credentials.notificationid
                ]
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/pushnotification/notice/delete', req , headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getInbox(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : null;

            let notificationcategoryid = "notificationcategoryid=" + (credentials.notificationcategoryid || 'null');
            let notificationisread = "notificationisread=" + (credentials.notificationisread || 'null');
            let searchinput = "searchinput=" + (credentials.search || 'null');
            let resultpage = "resultpage=" + page;
            let sortingcolumn = "sortingcolumn=null";
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "rowresultsperpage=" + 100 : "rowresultsperpage=" + credentials.rowresultsperpage;
            let r = [searchinput, notificationcategoryid, notificationisread, resultpage, sortingcolumn, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/admin/pushnotification/blast/listall' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getInboxItem(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/admin/pushnotification/blast/' +credentials.notificationid+ '/item', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getNotificationItem(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/admin/pushnotification/notice/' +credentials.notificationid+ '/item', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteInbox(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let req = {
                "notificationids": [
                    credentials.notificationid
                ]
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/admin/pushnotification/blast/delete', req , headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getPurchasedParkingTokens(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "parkingtokentrxref": null,
                "amountgrandtotalfrom": null,
                "amountgrandtotalto": null,
                "transactioncreateddatetimefrom": null,
                "transactioncreateddatetimeto": null,
                "parkingtokentrxstatus": null,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/view/transaction/purchase/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTransferredParkingTokens(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "parkingtokentrxref": null,
                "amountgrandtotalfrom": null,
                "amountgrandtotalto": null,
                "transactioncreateddatetimefrom": null,
                "transactioncreateddatetimeto": null,
                "parkingtokentrxstatus": null,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/view/transaction/transfer/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getIPAY88OnlineTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": null,
                "carparkid": null,
                "refno": null,
                "merchantrefno": null,
                "merchantcode": credentials.merchantcode == "" ? null : credentials.merchantcode,
                "transactionstartdate": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddate": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'SPPAY_RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/ipay88fpxdirect/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBinfiniteTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "refno": null,
                "merchantrefno": null,
                "transactionstartdate": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddate": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "TID": credentials.TID == "" ? null : credentials.TID,
                "resultpage": page,
                "sortingcolumn": 'SPPAY_RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/binfinite/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBoostTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "serviceapiid": null,
                "operatorid": null,
                "carparkid": null,
                "refno": null,
                "merchantrefno": null,
                "transactionstartdate": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddate": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/boost/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTNGTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "refno": null,
                "merchantid": null,
                "merchantrefno": null,
                "transactionstartdate": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddate": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/touchngoewallet/view/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTNGQRTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let MerchantTrxRefno = "MerchantTrxRefno=null";
            let PassRef = "PassRef=null";
            let RequestCreatedDateTimeStart = "RequestCreatedDateTimeStart=" + (credentials.transactionstartdate || 'null');
            let RequestCreatedDateTimeEnd = "RequestCreatedDateTimeEnd=" + (credentials.transactionenddate || 'null');
            let Search = "Search=" + (credentials.search || 'null');
            let Page = "Page=" + page;
            let Rows = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let Sort = "Sort=SPPAY_RequestCreatedDateTime";

            let r = [
                MerchantTrxRefno,
                PassRef,
                RequestCreatedDateTimeStart,
                RequestCreatedDateTimeEnd,
                Search,
                Page,
                Rows,
                Sort
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay_transaction/tngqr/view/search' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getDuitNowTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let QRID = "QRID="+ (credentials.merchantcode || 'null');
            let MerchantTrxRefno = "MerchantTrxRefno=null";
            let PassRef = "PassRef=null";
            let ControllerID = "ControllerID=null";
            let RequestCreatedDateTimeStart = "RequestCreatedDateTimeStart=" + (credentials.transactionstartdate || 'null');
            let RequestCreatedDateTimeEnd = "RequestCreatedDateTimeEnd=" + (credentials.transactionenddate || 'null');
            let Search = "Search=" + (credentials.search || 'null');
            let Page = "Page=" + page;
            let Rows = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let Sort = "Sort=SPPAY_RequestCreatedDateTime";

            let r = [
                QRID,
                MerchantTrxRefno,
                PassRef,
                ControllerID,
                RequestCreatedDateTimeStart,
                RequestCreatedDateTimeEnd,
                Search,
                Page,
                Rows,
                Sort
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay_transaction/duitnow/view/search' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getMerchantRewardSlotPurchaseReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "loginemailid": null,
                "slotgroupid": null,
                "purchasedatefrom": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "purchasedateto": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantreward/merchantrewardslotpurchase/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCollectedPromoVouchersReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "loginemailid": null,
                "slotgroupid": null,
                "slotid": null,
                "advertid": null,
                "loginmobileid": null,
                "collectdatefrom": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "collectdateto": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantreward/merchantrewardcollectpromo/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getRedeemedPromoVouchersReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "slotgroupid": null,
                "slotid": null,
                "advertid": null,
                "loginmobileid": null,
                "redeemdatefrom": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "redeemdateto": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantreward/merchantrewardredeempromo/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        freeParkingTokensReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "parkingtokentrxref": null,
                "transactioncreateddatetimefrom": credentials.transactioncreateddatetimefrom == "" || credentials.transactioncreateddatetimefrom == undefined ? null : credentials.transactioncreateddatetimefrom,
                "transactioncreateddatetimeto": credentials.transactioncreateddatetimeto == "" || credentials.transactioncreateddatetimeto == undefined ? null : credentials.transactioncreateddatetimeto,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/view/report/addtoken', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getIPAY88OnlineReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "merchantcode": credentials.merchantcode == "" ? null : credentials.merchantcode,
                "transactionstartdate": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddate": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'SPPAY_RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_report/remittance/ipay88fpxdirect/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTNGReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "merchantid": null,
                "transactionstartdate": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddate": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'SPPAY_RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_report/remittance/touchngoewallet/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTNGQRReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let MerchantTrxRefno = "MerchantTrxRefno=null";
            let PassRef = "PassRef=null";
            let RequestCreatedDateTimeStart = "RequestCreatedDateTimeStart=" + (credentials.transactionstartdate || 'null');
            let RequestCreatedDateTimeEnd = "RequestCreatedDateTimeEnd=" + (credentials.transactionenddate || 'null');
            let Search = "Search=" + (credentials.search || 'null');
            let Page = "Page=" + page;
            let Rows = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let Sort = "Sort=SPPAY_RequestCreatedDateTime";

            let r = [
                MerchantTrxRefno,
                PassRef,
                RequestCreatedDateTimeStart,
                RequestCreatedDateTimeEnd,
                Search,
                Page,
                Rows,
                Sort
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay_report/remittance/touchngoqr/search' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getDuitNowReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let QRID = "QRID="+ (credentials.merchantcode || 'null');
            let MerchantTrxRefno = "MerchantTrxRefno=null";
            //let PassRef = "PassRef=null";
            let ControllerID = "ControllerID=null";
            let RequestCreatedDateTimeStart = "RequestCreatedDateTimeStart=" + (credentials.transactionstartdate || 'null');
            let RequestCreatedDateTimeEnd = "RequestCreatedDateTimeEnd=" + (credentials.transactionenddate || 'null');
            let Search = "Search=" + (credentials.search || 'null');
            let Page = "Page=" + page;
            let Rows = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let Sort = "Sort=SPPAY_RequestCreatedDateTime";

            let r = [
                QRID,
                MerchantTrxRefno,
                //PassRef,
                ControllerID,
                RequestCreatedDateTimeStart,
                RequestCreatedDateTimeEnd,
                Search,
                Page,
                Rows,
                Sort
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay_report/remittance/duitnow/search' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingWheelLockReportAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let TrxnID = "TrxnID=null";
            let BayID = "BayID=null";
            let CarParkBayPrefix = "CarParkBayPrefix=null";
            let CarParkBayNo = "CarParkBayNo=null";
            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let ParkingEntryDateTimeFrom = "ParkingEntryDateTimeFrom=" + (credentials.transactionstartdate || 'null');
            let ParkingEntryDateTimeTo = "ParkingEntryDateTimeTo=" + (credentials.transactionenddate || 'null');
            let searchinput = "SearchInput=" + (credentials.search || 'null');
            let resultpage = "ResultPage=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "RowResultsPerPage=" + 100 : "RowResultsPerPage=" + credentials.rowresultsperpage;
            let SortingColumn = "SortingColumn=null";

            let r = [
                TrxnID,
                BayID,
                CarParkBayPrefix,
                CarParkBayNo,
                operatorid,
                carparkid,
                ParkingEntryDateTimeFrom,
                ParkingEntryDateTimeTo,
                searchinput,
                resultpage,
                rowresultsperpage,
                SortingColumn
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/report/all' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingWheelLockReportApp(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let TrxnID = "TrxnID=null";
            let BayID = "BayID=null";
            let CarParkBayPrefix = "CarParkBayPrefix=null";
            let CarParkBayNo = "CarParkBayNo=null";
            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let ParkingEntryDateTimeFrom = "ParkingEntryDateTimeFrom=" + (credentials.transactionstartdate || 'null');
            let ParkingEntryDateTimeTo = "ParkingEntryDateTimeTo=" + (credentials.transactionenddate || 'null');
            let searchinput = "SearchInput=" + (credentials.search || 'null');
            let resultpage = "ResultPage=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "RowResultsPerPage=" + 100 : "RowResultsPerPage=" + credentials.rowresultsperpage;
            let SortingColumn = "SortingColumn=null";

            let r = [
                TrxnID,
                BayID,
                CarParkBayPrefix,
                CarParkBayNo,
                operatorid,
                carparkid,
                ParkingEntryDateTimeFrom,
                ParkingEntryDateTimeTo,
                searchinput,
                resultpage,
                rowresultsperpage,
                SortingColumn
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/report/mobile' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingWheelLockReportPole(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let TrxnID = "TrxnID=null";
            let BayID = "BayID=null";
            let CarParkBayPrefix = "CarParkBayPrefix=null";
            let CarParkBayNo = "CarParkBayNo=null";
            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let ParkingEntryDateTimeFrom = "ParkingEntryDateTimeFrom=" + (credentials.transactionstartdate || 'null');
            let ParkingEntryDateTimeTo = "ParkingEntryDateTimeTo=" + (credentials.transactionenddate || 'null');
            let searchinput = "SearchInput=" + (credentials.search || 'null');
            let resultpage = "ResultPage=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "RowResultsPerPage=" + 100 : "RowResultsPerPage=" + credentials.rowresultsperpage;
            let SortingColumn = "SortingColumn=null";

            let r = [
                TrxnID,
                BayID,
                CarParkBayPrefix,
                CarParkBayNo,
                operatorid,
                carparkid,
                ParkingEntryDateTimeFrom,
                ParkingEntryDateTimeTo,
                searchinput,
                resultpage,
                rowresultsperpage,
                SortingColumn
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/report/pole' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getParkingWheelLockReportNoPayment(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let TrxnID = "TrxnID=null";
            let BayID = "BayID=null";
            let CarParkBayPrefix = "CarParkBayPrefix=null";
            let CarParkBayNo = "CarParkBayNo=null";
            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let ParkingEntryDateTimeFrom = "ParkingEntryDateTimeFrom=" + (credentials.transactionstartdate || 'null');
            let ParkingEntryDateTimeTo = "ParkingEntryDateTimeTo=" + (credentials.transactionenddate || 'null');
            let searchinput = "SearchInput=" + (credentials.search || 'null');
            let resultpage = "ResultPage=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "RowResultsPerPage=" + 100 : "RowResultsPerPage=" + credentials.rowresultsperpage;
            let SortingColumn = "SortingColumn=null";

            let r = [
                TrxnID,
                BayID,
                CarParkBayPrefix,
                CarParkBayNo,
                operatorid,
                carparkid,
                ParkingEntryDateTimeFrom,
                ParkingEntryDateTimeTo,
                searchinput,
                resultpage,
                rowresultsperpage,
                SortingColumn
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/report/parknrun' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTNGFinanceSFTP(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "folderyyyymmdd": credentials.folderyyyymmdd
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/touchngoewallet/sftp/listfile', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTNGFinanceSFTPBase64(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "folderyyyymmdd": credentials.folderyyyymmdd,
                "filenamewithext": credentials.filenamewithext
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/touchngoewallet/sftp/listfile/getfile', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTNGQRFinanceSFTP(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "folderyyyymmdd": credentials.folderyyyymmdd
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/touchngoewallet/qr/sftp/listfile', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTNGQRFinanceSFTPBase64(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "folderyyyymmdd": credentials.folderyyyymmdd,
                "filenamewithext": credentials.filenamewithext
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/touchngoewallet/qr/sftp/listfile/getfile', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getiPayMerchantCode(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppaypaymentmethodkeys/ipay88fpxdirect/view', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getQRID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay/master/qrid/list?Page=1&Rows=null&Sort=null', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getQRIDList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let ID = "ID="+ (credentials.merchantcode || 'null');
            let CarParkID = "CarParkID=" + (credentials.carparkid || 'null');
            let OperatorID = "OperatorID=" + (credentials.operatorid || 'null');
            let ControllerID = "ControllerID=" + (credentials.ControllerID || 'null');
            let ControllerType = "ControllerType=" + (credentials.ControllerType || 'null');
            let Search = "Search=" + (credentials.search || 'null');
            let Page = "Page=" + page;
            let Rows = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "Rows=" + 100 : "Rows=" + credentials.rowresultsperpage;
            let Sort = "Sort=CreatedDateTime";

            let r = [
                ID,
                CarParkID,
                OperatorID,
                ControllerID,
                ControllerType,
                Search,
                Page,
                Rows,
                Sort
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay/master/qrid/list' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createQRID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ControllerID": credentials.ControllerID,
                "QRID": credentials.QRID,
                "ControllerType": credentials.ControllerType,
                "VirtualAccName": credentials.VirtualAccName,
                "VirtualAccNo": credentials.VirtualAccNo,
                "Remark": credentials.Remark
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay/master/qrid/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        editQRID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "ControllerID": credentials.ControllerID,
                "QRID": credentials.QRID,
                "ControllerType": credentials.ControllerType,
                "VirtualAccName": credentials.VirtualAccName,
                "VirtualAccNo": credentials.VirtualAccNo,
                "Remark": credentials.Remark
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay/master/qrid/' + credentials.ID + '/edit', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteQRID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay/master/qrid/' + credentials.ID + '/delete', {}, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateQRIDisDefault(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "QRID": credentials.QRID,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay/master/qrid/default/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBInfiniteTIDCode(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppaypaymentmethodkeys/binfiniteewallet/view', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBinfiniteReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "transactionstartdate": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddate": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "MID": null,
                "TID": credentials.TID == "" ? null : credentials.TID,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'SPPAY_RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_report/remittance/binfinite/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBoostReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "serviceapiid": null,
                "paymentoption": null,
                "transactionstartdate": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddate": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'SPPAY_RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_report/remittance/boost/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getDeductedParkingTokensReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "parkingtokentrxref": null,
                "transactioncreateddatetimefrom": credentials.transactioncreateddatetimefrom == "" || credentials.transactioncreateddatetimefrom == undefined ? null : credentials.transactioncreateddatetimefrom,
                "transactioncreateddatetimeto": credentials.transactioncreateddatetimeto == "" || credentials.transactioncreateddatetimeto == undefined ? null : credentials.transactioncreateddatetimeto,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'SPPAY_RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/view/report/deducttoken', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getPurchasedParkingTokensReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "parkingtokentrxref": null,
                "loginmobileid": null,
                "transactioncreateddatetimefrom": credentials.transactioncreateddatetimefrom == "" || credentials.transactioncreateddatetimefrom == undefined ? null : credentials.transactioncreateddatetimefrom,
                "transactioncreateddatetimeto": credentials.transactioncreateddatetimeto == "" || credentials.transactioncreateddatetimeto == undefined ? null : credentials.transactioncreateddatetimeto,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'SPPAY_RequestCreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/view/report/purchase', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getOperatorRemittance(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "transactionstartdatetime": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddatetime": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'CreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/operatorremittance/all/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getOperatorRemittanceReader(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "transactionstartdatetime": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactionenddatetime": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'CreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/operatorremittance/reader/search', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCasualParkingReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "loginmobileid": null,
                "parkingfromdatetime": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "parkingtodatetime": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'ParkingExitDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/transactions/snatch/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getSpecialRateParkingReport(context,credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == ""? null: credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null: credentials.carparkid,
                "startDate": credentials.transactionstartdate == ""|| credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "endDate": credentials.transactionenddate == ""|| credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "search":credentials.search == "" ? null : credentials.search,
                "rows": credentials.rowsresultperpage == "" || credentials.rowsresultsperpage == undefined ? 100 : credentials.rowsresultsperpage,
                "page":page
            };

            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/specialratevalidation/report', JSON.stringify(rq), headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        getAllParkingReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "parkingfromdatetime": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "parkingtodatetime": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'ParkingExitDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/transactions/all/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getSeasonParkingReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "loginmobileid": null,
                "parkingfromdatetime": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "parkingtodatetime": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'ParkingExitDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/transactions/snatchseason/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getManualExitReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "manualexitcreateddatetimefrom": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "manualexitcreateddatetimeto": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "sortingcolumn": 'ParkingExitDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/transactions/snatchmanualexit/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getManualEntryReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            const search= credentials.search != null && credentials.search != '' ? "search=" + credentials.search : '';
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            const starttime= credentials.transactionstartdate == ""  || credentials.transactionstartdate == ""? '' : "starttime="+credentials.transactionstartdate;
            const endtime= credentials.transactionenddate == ""  || credentials.transactionenddate == ""? '' : "endtime="+credentials.transactionenddate;
            const page= credentials.page == ""  || credentials.page == ""? '' : "page="+credentials.page;
            console.log(credentials);
            let r = [
                search,
                carparkid,
                starttime,
                endtime
            ];
            let urlReq = urlreq.createReq(r);
            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parking/transactions/snatchmanualentry/report'+urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBayLockGracePeriodReportAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "SearchInput=" + (credentials.search || 'null');
            let entrytime = "ParkingEntryDateTimeFrom=" + (credentials.transactionstartdate || null);
            let exittime = "ParkingEntryDateTimeTo=" + (credentials.transactionenddate || null);
            let resultpage = "ResultPage=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "RowResultsPerPage=" + 100 : "RowResultsPerPage=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, entrytime, exittime, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/report/grace' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getBayLockManualExitReportAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "OperatorID=" + (credentials.operatorid || 'null');
            let carparkid = "CarParkID=" + (credentials.carparkid || 'null');
            let searchinput = "SearchInput=" + (credentials.search || 'null');
            let entrytime = "ManualExitTriggerDateTimeFrom=" + (credentials.transactionstartdate || null);
            let exittime = "ManualExitTriggerDateTimeTo=" + (credentials.transactionenddate || null);
            let resultpage = "ResultPage=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "RowResultsPerPage=" + 100 : "RowResultsPerPage=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, entrytime, exittime, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingwheellock/report/manualexit' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getPurchasedValuePassReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "purchasedatefrom": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "purchasedateto": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                //"sortingcolumn": 'CreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vppurchasebyvpproductid/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getTransferredValuePassReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "loginmobileid": null,
                "transferdatefrom": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transferdateto": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                //"sortingcolumn": 'CreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vpunittransfer/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getUsedValuePassReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "utlizationdatefrom": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "utlizationdateto": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                //"sortingcolumn": 'CreatedDateTime',
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/vpunitutilization/report', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createCalendar(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "countrycode": credentials.countrycode,
                "statecode": credentials.statecode,
                "citycode": credentials.citycode,
                "dayname": credentials.dayname,
                "daytype": credentials.daytype,
                "daydate": credentials.daydate
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_calendar/register/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateCalendar(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "calendardayid": credentials.calendardayid,
                "countrycode": credentials.countrycode,
                "statecode": credentials.statecode,
                "citycode": credentials.citycode,
                "dayname": credentials.dayname,
                "daytype": credentials.daytype,
                "daydate": credentials.daydate,
                "activestatus": credentials.activestatus
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_calendar/update/calendardayid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteCalendar(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "calendardayid": Number(credentials.calendardayid),
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_calendar/delete/calendardayid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCalendarValues(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "countrycode": null,
                "statecode": null,
                "citycode": null,
                "inputsearch": null
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/config_calendar/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getAllParkingTokens(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/parkingtoken/list/all', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createParkingToken(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "parkingtokenamount": credentials.parkingtokenamount,
                "parkingtokenpricecost": credentials.parkingtokenpricecost,
                "activestatus": credentials.activestatus,
                "mobiledisplaystatus": credentials.mobiledisplaystatus
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/bundle/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateParkingToken(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "parkingtokenbundleid": credentials.parkingtokenbundleid,
                "parkingtokenamount": credentials.parkingtokenamount,
                "parkingtokenpricecost": credentials.parkingtokenpricecost,
                "activestatus": credentials.activestatus,
                "mobiledisplaystatus": credentials.mobiledisplaystatus
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/bundle/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteParkingToken(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "parkingtokenbundleid": credentials.parkingtokenbundleid,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/bundle/delete', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deductParkingTokensList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "parkingtokentrxref": null,
                "amountgrandtotalfrom": null,
                "amountgrandtotalto": null,
                "transactioncreateddatetimefrom": credentials.transactioncreateddatetimefrom == "" || credentials.transactioncreateddatetimefrom == undefined ? null : credentials.transactioncreateddatetimefrom,
                "transactionenddate": credentials.transactioncreateddatetimeto == "" || credentials.transactioncreateddatetimeto == undefined ? null : credentials.transactioncreateddatetimeto,
                "parkingtokentrxstatus": null,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/view/transaction/deducttoken/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createDeductParkingTokens(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginmobileid": credentials.loginmobileid,
                "paymenttokenamount": credentials.paymenttokenamount,
                "reason": credentials.reason,
                "payeename": credentials.payeename,
                "bankname": credentials.bankname,
                "bankaccno": credentials.bankaccno,
                "icnobusinessregno": credentials.icnobusinessregno
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/transact/deducttoken', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        freeParkingTokensList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "parkingtokentrxref": null,
                "transactioncreateddatetimefrom": credentials.transactioncreateddatetimefrom == "" || credentials.transactioncreateddatetimefrom == undefined ? null : credentials.transactioncreateddatetimefrom,
                "transactioncreateddatetimeto": credentials.transactioncreateddatetimeto == "" || credentials.transactioncreateddatetimeto == undefined ? null : credentials.transactioncreateddatetimeto,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/view/transaction/addtoken/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createFreeParkingTokens(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginmobileid": credentials.loginmobileid,
                "paymenttokenamount": credentials.paymenttokenamount,
                "reason": credentials.reason,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parkingtoken/transact/addtoken', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCustomerParkingHistory(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "resultpage": page,
                "trxref": null,
                "searchinput": credentials.search,
                "loginmobileid": null,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
                "transactiondatetimestart": credentials.transactionstartdate == "" || credentials.transactionstartdate == undefined ? null : credentials.transactionstartdate,
                "transactiondatetimeend": credentials.transactionenddate == "" || credentials.transactionenddate == undefined ? null : credentials.transactionenddate,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/transaction/customerhistory/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCustomerParkingHistoryByTrxRef(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "trxref": credentials.trxref,
                "loginmobileid": credentials.loginmobileid
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/transaction/customerhistory/view/bytrxrefloginmobileid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createSpecialRateValidation(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "carparkid": credentials.carparkid == "null" ? null : credentials.carparkid,
                "ratecardid": Number(credentials.ratecardid),
                "startdatetime": credentials.startdatetime,
                "enddatetime": credentials.enddatetime,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/specialratevalidation/newspecial', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateSpecialRateValidation(context, credentials){
            console.log(credentials)
            axios.defaults.headers.post['Content-Type']='application/json';
            const headers ={
                headers:{'Authorization': `JWT ${wsutil.state.jwtToken}`}
            };

            const rq = {
                'CarParkID': credentials.carparkid == "null" ? null : credentials.carparkid,
                'RateCardID': Number(credentials.ratecardid),
                'StartDateTime': credentials.startdatetime,
                'EndDateTime': credentials.enddatetime,
                'Enabled': credentials.status,
                
            }

            return new Promise((resolve, reject)=>{
                axios.post(wsutil.state.apiBaseUrl + 'api/specialratevalidation/'+ credentials.specialrateid +'/update', JSON.stringify(rq), headers)
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(function(error){
                    console.log(error)
                    reject(error);
                });
            });
        },

        getSPFee(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "productcode": null,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/spfee/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createSPFee(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid,
                "carparkid": credentials.carparkid == "null" ? null : credentials.carparkid,
                "productcode": credentials.productcode,
                "spfeeADM": credentials.adminfee,
                "spfeeOP": credentials.operatorfee,
                "feestartdate": credentials.feestartdate
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/spfee/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateSPFee(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "spfeeid": credentials.spfeeid,
                "operatorid": credentials.operatorid,
                "carparkid": credentials.carparkid == "null" ? 0 : credentials.carparkid,
                "productcode": credentials.productcode,
                "spfeeADM": credentials.adminfee,
                "spfeeOP": credentials.operatorfee,
                "feestartdate": credentials.feestartdate
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/spfee/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteSPFee(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "spfeeid": credentials.spfeeid,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/master/spfee/delete', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getBInfinitePayTID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_keyconfiguration/binfiniteewallet/view/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createBInfinitePayTID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "carparkid": credentials.carparkid == "null" ? null : credentials.carparkid,
                "tid": Number(credentials.tid),
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_keyconfiguration/binfiniteewallet/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateBInfinitePayTID(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "relationid": Number(credentials.relationid),
                "tid": Number(credentials.tid),
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_keyconfiguration/binfiniteewallet/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getAllProductCode(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/bills_productcode/view/all', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        sentActivationCode(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "mobile": credentials.mobile == "" ? null : credentials.mobile,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sms/sent/pac', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        verifiedActivationCode(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "mobile": credentials.mobile == "" ? null : credentials.mobile,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sms/verify/pac', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        smsNotification(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "mobile": credentials.mobile == "" ? null : credentials.mobile,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sms/sent/ntfy', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        allSMS(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "smstype": credentials.smstype == "" ? null : credentials.smstype,
                "mobile": null,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
                "searchinput": credentials.search == "" ? null : credentials.search,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sms/sent/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getMerchantRewardSlotGroup(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "slotgroupid": null,
                "validstartenddate": null,
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "haveslotsavailable": null,
                "publicviewstatus": null,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslotgroup/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getMerchantRewardSlotGroupAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "slotgroupid": null,
                "validstartenddate": null,
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "haveslotsavailable": null,
                "publicviewstatus": null,
                "resultpage": 1,
                "rowresultsperpage": null,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslotgroup/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createMerchantRewardSlotGroup(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "slotgroupname": credentials.slotgroupname,
                "carparkid": credentials.carparkid,
                "startdate": credentials.startdate,
                "enddate": credentials.enddate,
                "totalslots": credentials.totalslots,
                "purchasedurationperslot": credentials.purchasedurationperslot,
                "purchasedurationunit": credentials.purchasedurationunit,
                "costperslot": credentials.costperslot,
                "havepromovoucher": credentials.havepromovoucher,
                "unitpriceperpromovoucher": credentials.unitpriceperpromovoucher,
                "promovoucherquantity": credentials.promovoucherquantity,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslotgroup/create', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateMerchantRewardSlotGroup(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "slotgroupid": credentials.slotgroupid,
                "slotgroupname": credentials.slotgroupname,
                "carparkid": credentials.carparkid,
                "startdate": credentials.startdate,
                "enddate": credentials.enddate,
                "purchasedurationperslot": credentials.purchasedurationperslot,
                "purchasedurationunit": credentials.purchasedurationunit,
                "costperslot": credentials.costperslot,
                "havepromovoucher": credentials.havepromovoucher,
                "unitpriceperpromovoucher": credentials.unitpriceperpromovoucher,
                "promovoucherquantity": credentials.promovoucherquantity,
                "totalslots": credentials.totalslots,
                "publicviewstatus": credentials.publicviewstatus,
                "delete": false
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslotgroup/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getMerchantRewardSlot(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginemailid": credentials.loginemailid == "" || credentials.loginemailid == undefined ? null : credentials.loginemailid,
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "slotid": credentials.slotid == "" || credentials.slotid == undefined ? null : credentials.slotid,
                "slotgroupid": credentials.slotgroupid == "" || credentials.slotgroupid == undefined ? null : credentials.slotgroupid,
                "advertid": null,
                "contactemail": null,
                "mobiledisplaystatus": credentials.mobiledisplaystatus == true ? 1 : credentials.mobiledisplaystatus == false ? 0 : credentials.mobiledisplaystatus,
                "hasredeemablepromo": null,
                "promousevaliddate": null,
                "havepromotoredeem": null,
                "advertvaliddate": null,
                "approvalstatus": null,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslot/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createMerchantRewardSlot(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginemailid": credentials.loginemailid,
                "slotgroupid": credentials.slotgroupid,
                "purchasequantity": credentials.purchasequantity,
                "paymentgateway": credentials.paymentgateway,
                "paymenttrxid": credentials.paymenttrxid,
                "paymenttrxdatetime": credentials.paymenttrxdatetime
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslot/createnew', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateMerchantRewardSlot(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginemailid": credentials.loginemailid,
                "slotid": credentials.slotid,
                "contactemail": credentials.contactemail,
                "contactnumber": credentials.contactnumber,
                "merchantname": credentials.merchantname,
                "promousestartdate": credentials.promousestartdate,
                "promouseenddate": credentials.promouseenddate,
                "addetail": {
                    "adtitle": credentials.adtitle,
                    "addescription": credentials.addescription,
                    "adtnc": credentials.adtnc,
                    "url": credentials.url,
                    "adstartdate": credentials.adstartdate,
                    "adenddate": credentials.adenddate,
                    "image1": credentials.image1,
                    "image2": credentials.image2
                }
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslot/updateexisting', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateMerchantRewardSlotMobileStatus(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "slotid": credentials.slotid,
                "mobiledisplaystatus": credentials.mobiledisplaystatus
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslot/mobiledisplaystatus/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getCollectedPromoVouchers(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginemailid": credentials.loginemailid == "" || credentials.loginemailid == undefined ? null : credentials.loginemailid,
                "operatorid": credentials.operatorid == "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid == "" ? null : credentials.carparkid,
                "promoclaimid": null,
                "slotid": credentials.slotid == "" || credentials.slotid == undefined ? null : credentials.slotid,
                "loginmobileid": null,
                "promoused": credentials.promoused == true ? 1 : credentials.promoused == false ? 0 : credentials.promoused,
                "promousevaliddate": null,
                "searchinput": credentials.search == "" ? null : credentials.search,
                "resultpage": page,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardpromo/list/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getReviewItems(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/merchantrewardslot/review/description/get', headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createReviewReason(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "description": credentials.description,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslot/review/description/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateReviewReason(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "reviewid": credentials.reviewid,
                "description": credentials.description,
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslot/review/description/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        changeApprovalStatus(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "loginemailid": credentials.loginemailid,
                "slotid": credentials.slotid,
                "approvalstatus": credentials.approvalstatus,
                "reviewid": credentials.reviewid
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/merchantrewardslot/review/approvalstatus/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        checkiPayTrxnStatus(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";
            let gateway = credentials.gateway;
            let ref = credentials.ref;

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/merchantrewardslot/purchase/checktransactionstatus/' + gateway + '/' + ref, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createVPUnitTransfer(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "fromloginmobileid": credentials.fromloginmobileid,
                "tologinmobileid": credentials.tologinmobileid,
                "valuepasspassid": credentials.valuepasspassid
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/valuepass/transact/transferbetweenloginmobileid', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getProductServicesFees(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/config_servicefee/list/' + credentials.bywhat  + '/' + credentials.productcode, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getFindMyCarAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let operatorid = "operatorid=" + (credentials.operatorid || 'null');
            let carparkid = "carparkid=" + (credentials.carparkid || 'null');
            let searchinput = "searchinput=" + (credentials.search || 'null');
            let resultpage = "resultpage=" + page;
            let rowresultsperpage = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "rowresultsperpage=" + 100 : "rowresultsperpage=" + credentials.rowresultsperpage;
            let r = [operatorid, carparkid, searchinput, resultpage, rowresultsperpage];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/findmycar/qrdetails/listall' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        createFindMyCar(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": credentials.carparkid,
                "zone": credentials.zone,
                "level": credentials.level,
                "pillarbay": credentials.pillarbay
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/findmycar/qrdetails/new', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        updateFindMyCar(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": credentials.carparkid,
                "zone": credentials.zone,
                "level": credentials.level,
                "pillarbay": credentials.pillarbay
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/findmycar/qrdetails/'+ credentials.qrcodeid +'/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        deleteFindMyCar(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/findmycar/qrdetails/'+ credentials.qrcodeid  +'/delete',{}, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getSubscriberRateCardInfo(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';

            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/specialratevalidation/selected/'+ credentials.TicketID, headers)
                .then(response=>{
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },

        getTNGCardReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let RequestCreatedDateTimeStart = "start=" + (credentials.transactionstartdate || 'null');
            let RequestCreatedDateTimeEnd = "end=" + (credentials.transactionenddate || 'null');
            let Search = credentials.search != null || credentials.search != '' ? "search=" + credentials.search : '';
            let Page = "page=" + page;
            let Rows = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "rows=" + 100 : "rows=" + credentials.rowresultsperpage;

            let r = [
                RequestCreatedDateTimeStart,
                RequestCreatedDateTimeEnd,
                Search,
                Page,
                Rows,
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay_report/remittance/tngcard/search' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getVisaMasterReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let MerchantTrxRefno = "MerchantTrxRefno=null";
            let PassRef = "PassRef=null";
            let RequestCreatedDateTimeStart = "start=" + (credentials.transactionstartdate || 'null');
            let RequestCreatedDateTimeEnd = "end=" + (credentials.transactionenddate || 'null');
            let Search = credentials.search != null || credentials.search != '' ? "search=" + credentials.search : '';
            let Page = "page=" + page;
            let Rows = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "rows=" + 100 : "rows=" + credentials.rowresultsperpage;
            let Sort = "Sort=SPPAY_RequestCreatedDateTime";

            let r = [
                MerchantTrxRefno,
                PassRef,
                RequestCreatedDateTimeStart,
                RequestCreatedDateTimeEnd,
                Search,
                Page,
                Rows,
                Sort
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay_report/remittance/visamaster/search' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getTNGCardTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let RequestCreatedDateTimeStart = "startdate=" + (credentials.transactionstartdate || 'null');
            let RequestCreatedDateTimeEnd = "enddate=" + (credentials.transactionenddate || 'null');
            let Search = credentials.search != null || credentials.search != '' ? "search=" + credentials.search : '';
            let Page = "page=" + page;
            let Rows = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "rows=" + 100 : "rows=" + credentials.rowresultsperpage;
            let Sort = "Sort=SPPAY_RequestCreatedDateTime";
            let type = credentials.type != null && credentials.type != '' ? "type=" + credentials.type : '';
            let carparkid = credentials.carparkid != null && credentials.carparkid != '' ? "carparkid=" + credentials.carparkid : '';
            let operatorid = credentials.operatorid != null && credentials.operatorid != '' ? "operatorid=" + credentials.operatorid : '';
            let r = [
                RequestCreatedDateTimeStart,
                RequestCreatedDateTimeEnd,
                Search,
                Page,
                Rows,
                type,
                carparkid,
                operatorid
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay_transaction/tngcard/view/search' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getVisaMasterTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let RequestCreatedDateTimeStart = "startdate=" + (credentials.transactionstartdate || 'null');
            let RequestCreatedDateTimeEnd = "enddate=" + (credentials.transactionenddate || 'null');
            let Search = credentials.search != null && credentials.search != '' ? "search=" + credentials.search : '';
            let Page = "page=" + page;
            let Rows = credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? "rows=" + 100 : "rows=" + credentials.rowresultsperpage;
            let Sort = "Sort=SPPAY_RequestCreatedDateTime";
            let type = credentials.type != null && credentials.type != '' ? "type=" + credentials.type : '';
            let carparkid = credentials.carparkid != null && credentials.carparkid != '' ? "carparkid=" + credentials.carparkid : '';
            let operatorid = credentials.operatorid != null && credentials.operatorid != '' ? "operatorid=" + credentials.operatorid : '';
            let r = [
                RequestCreatedDateTimeStart,
                RequestCreatedDateTimeEnd,
                Search,
                Page,
                Rows,
                type,
                carparkid,
                operatorid
            ];

            let urlReq = urlreq.createReq(r);

              return new Promise((resolve, reject) => {
                  axios.get(wsutil.state.apiBaseUrl + 'api/sppay_transaction/visamaster/view/search' + urlReq, headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getLprCarplate(context, credentials){
            console.log(credentials);
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            const imageType = credentials.imageType != '' && credentials.imageType ? 'imagetype=' + credentials.imageType : '';

            let r = [
                imageType
            ];

            let urlReq = urlreq.createReq(r);

            return new Promise((resolve, reject)=>{
                axios.get(wsutil.state.apiBaseUrl + 'api/do/lpr/carpark/'+ credentials.carparkid +'/carplateno/' + credentials.carplateNo + '/image' + urlReq, headers)
                .then(response=>{
                    console.log(response)
                    resolve(response);
                }).catch(function(error){
                    reject(error)
                });
            });

        },
        getLPRParkingEntry(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";
            console.log(credentials);
            if(credentials.startdate != '' || credentials.startdate != undefined || credentials.startdate != null ){
                 credentials.startdate = moment(credentials.startdate).subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss');
            }
            if(credentials.enddate != '' || credentials.enddate != undefined || credentials.enddate != null){
                 credentials.enddate = moment(credentials.enddate).subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss');
            }
            if(credentials.startdate.includes('Invalid')){
                credentials.startdate = null
            }
            if(credentials.enddate.includes('Invalid')){
                credentials.enddate = null
            }
            let rq = {
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "resultpage": page,
                "startdate":credentials.startdate == "" || credentials.startdate == undefined || credentials.startdate == null ? null : credentials.startdate,
                "enddate":credentials.enddate == "" || credentials.enddate == undefined || credentials.enddate == null ? null : credentials.enddate,
                "searchinput": credentials.search,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }
            
            return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/lpr/view/inparking/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
            });
        },
        getLPRImageDropDown(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            const carparkid= "carparkid=" + credentials.carparkid;
            const gateid= "gateid=" + credentials.gateid;
            
            console.log(credentials);
            let r = [
                carparkid,
                gateid
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/do/lpr/images' +  urlReq , headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },
        createLprTrigger(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "gateid": credentials.gateid,
                "token": credentials.token,
                "carplateno": credentials.carplateno,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/do/lpr/trigger/exit', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getPayeeList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            const search= credentials.search != null ? "search=" + credentials.search : '';
            const page= credentials.page == ""  || credentials.page == ""? '' : "page="+credentials.page;
            const rows= credentials.rows == ""  || credentials.rows == ""? '' : "rows="+credentials.rows;
            console.log(credentials);
            let r = [
                search,
                page,
                rows
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/payee/view/all' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getPayeeTransactionList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            const search= credentials.search != null && credentials.search != '' ? "search=" + credentials.search : '';
            const operatorid = credentials.operatorid == "" ||credentials.operatorid == null ? '' : "operatorid=" +credentials.operatorid;
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            const page= credentials.page == ""  || credentials.page == ""? 'page=1' : "page="+credentials.page;
            console.log(credentials);
            let r = [
                search,
                operatorid,
                carparkid,
                page
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/payee/transaction/view/all' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getPayeeReportList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            
            const search= credentials.search != null && credentials.search != '' ? "search=" + credentials.search : '';
            const operatorid = credentials.operatorid == "" ||credentials.operatorid == null ? '' : "operatorid=" +credentials.operatorid;
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            const page= credentials.page == null  && credentials.page == ""? 'page=1' : "page="+credentials.page;
            const rows= credentials.rowresultsperpage == null && credentials.rowresultsperpage == ""  ? 'rows=100' : "rows="+credentials.rowresultsperpage;
            const starttime= credentials.transactionstartdate == ""  || credentials.transactionstartdate == ""? '' : "parkingfromdatetime="+credentials.transactionstartdate;
            const endtime= credentials.transactionenddate == ""  || credentials.transactionenddate == ""? '' : "parkingtodatetime="+credentials.transactionenddate;
            console.log(credentials);
            let r = [
                search,
                operatorid,
                carparkid,
                page,
                starttime,
                endtime,
                rows
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/payee/report/view/all' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        createLprTriggerEntry(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            console.log(credentials)
            let rq = {
                "gateid": credentials.gateid,
                "token": credentials.token,
                "carplateno": credentials.carplateno,
                "entrytime": credentials.entrytime,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/do/lpr/carplateno/manual', JSON.stringify(rq), headers)
                  .then(response => {
                    console.log(response)
                      resolve(response);
                  })
                  .catch(function(error){
                    console.log(error)
                      reject(error);
                  });
              });
        },
         getLPRParkingTransactionAll(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "resultpage": page,
                "operatorid": credentials.operatorid === "" ? null : credentials.operatorid,
                "carparkid": credentials.carparkid === "" ? null : credentials.carparkid,
                "searchinput": credentials.search === "" ? null : credentials.search,
                "rowresultsperpage": credentials.rowresultsperpage == "" || credentials.rowresultsperpage == undefined ? 100 : credentials.rowresultsperpage,
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/parking/lpr/view/transactions/all', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },


        updateCarplateNo(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "carparkid": credentials.carparkid,
                "oldcarplate": credentials.oldcarplate,
                "newcarplate": credentials.newcarplate,
            }
              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/do/lpr/carplateno/update', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        getImageList(context, credentials) {
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            console.log(credentials)
            
            const search= credentials.search != null && credentials.search != '' ? "plateno=" + credentials.search : '';
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            const gate = credentials.gate != '' ? 'gate='+credentials.gate : '';
            const type = credentials.type != '' && credentials.type != 'All Image Type' ? 'type='+credentials.type : '';
            const page= credentials.page == ""  || credentials.page == ""? '' : "page="+credentials.page;
            console.log(credentials);
            let r = [
                search,
                carparkid,
                type,
                gate,
                page
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/do/lpr/images/all' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getImageUnknownList(context, credentials) {
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            
            // const operatorid = credentials.operatorid == "" ||credentials.operatorid == null ? null : "operatorid=" +credentials.operatorid;
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            const page= credentials.page == ""  || credentials.page == ""? '' : "page="+credentials.page;
            console.log(credentials);
            let r = [
                carparkid,
                page
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/do/lpr/unknown/all' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getLprTriggerTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            console.log(credentials)
            
            const search= credentials.search != null && credentials.search != '' ? "plateno=" + credentials.search : '';
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            console.log(credentials);
            let r = [
                search,
                carparkid,
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/lpr/trigger/transaction' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getLprExitReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            console.log(credentials)
            
            const search= credentials.search != null && credentials.search != '' ? "plateno=" + credentials.search : '';
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            const starttime= credentials.transactionstartdate == ""  || credentials.transactionstartdate == ""? '' : "starttime="+credentials.transactionstartdate;
            const endtime= credentials.transactionenddate == ""  || credentials.transactionenddate == ""? '' : "endtime="+credentials.transactionenddate;
            console.log(credentials);
            let r = [
                search,
                carparkid,
                starttime,
                endtime
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/lpr/trigger/report' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },

        getLprTriggerEntryTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            console.log(credentials)
            
            const search= credentials.search != null && credentials.search != '' ? "plateno=" + credentials.search : '';
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            console.log(credentials);
            let r = [
                search,
                carparkid,
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/lpr/trigger/entry/transaction' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },

        getLprEntryReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            console.log(credentials)
            
            const search= credentials.search != null && credentials.search != '' ? "search=" + credentials.search : '';
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            const starttime= credentials.transactionstartdate == ""  || credentials.transactionstartdate == ""? '' : "starttime="+credentials.transactionstartdate;
            const endtime= credentials.transactionenddate == ""  || credentials.transactionenddate == ""? '' : "endtime="+credentials.transactionenddate;
            console.log(credentials);
            let r = [
                search,
                carparkid,
                starttime,
                endtime
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/lpr/trigger/entry/report' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },

        getLprTriggerUpdatePlateTransaction(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            console.log(credentials)
            
            const search= credentials.search != null && credentials.search != '' ? "plateno=" + credentials.search : '';
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            console.log(credentials);
            let r = [
                search,
                carparkid,
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/lpr/trigger/updateplate/transaction' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getLprTriggerUpdatePlateReport(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            console.log(credentials)
            
            const search= credentials.search != null && credentials.search != '' ? "search=" + credentials.search : '';
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            console.log(credentials);
            let r = [
                search,
                carparkid,
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/lpr/trigger/updateplate/report' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },

        checkUniqueName(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get('jwtToken')}`}
            }

            console.log(credentials);
            
            let req=  {
                'urlName': credentials,
            }

            return new Promise((resolve, reject)=>{
                axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/verify/web/paymenturl', JSON.stringify(req), headers)
                .then(response=> {
                    resolve(response);
                })
                .catch((error)=>{
                    reject(error);
                });
            });
        },
        

        sendLog(context, credentials){
            const headers = {
                headers: {'token': 'd5eccf1f-83db-4238-ae3c-2528f0769cd7', 'tokenID': '1'}
              };
  
            let rq = {
                message1: credentials.message1,
                message2: credentials.message2,
                referenceID: credentials.id,
                incidentDate: parseInt((new Date().getTime() / 1000).toFixed(0)),
                level: credentials.level,
                moduleID: credentials.moduleID
            };
  
              return new Promise((resolve, reject) => {
                  axios.post('https://belog.snatchpark.com/logger', rq, headers )
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
          },
        getDuitNowFinanceSFTP(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "folderyyyymmdd": credentials.folderyyyymmdd
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/duitnow/sftp/listfile', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getDuitNowFinanceSFTPBase64(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let page = credentials.hasOwnProperty('page') ? credentials.page : "";

            let rq = {
                "folderyyyymmdd": credentials.folderyyyymmdd,
                "filenamewithext": credentials.filenamewithext
            }

            //let selectedkey = Object.keys(rq).find(item => item == Object.keys(credentials.chosen)[0]);
            //rq[selectedkey] = Object.values(credentials.chosen)[0] == "" ? null : Object.values(credentials.chosen)[0];

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/sppay_transaction/touchngoewallet/qr/sftp/listfile/getfile', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },
        updateUniqueName(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get('jwtToken')}`}
            }

            console.log(credentials);
            
            let req=  {
                'url': credentials.name,
                'type': credentials.type,
                'carparkid': credentials.carparkid
            };

            return new Promise((resolve, reject)=>{
                axios.post(wsutil.state.apiBaseUrl + 'api/config_carpark/update/paymenturl', JSON.stringify(req), headers)
                .then(response=> {
                    resolve(response);
                })
                .catch((error)=>{
                    reject(error);
                });
            });
        },
        getWLPayeeList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            const search= credentials.search != null ? "search=" + credentials.search : '';
            const page= credentials.page != null ? "page=" + credentials.page : '';
            
            console.log(credentials);
            let r = [
                search,
                page,
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/wl/payee/view/all' + urlReq, headers)
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getWLPayeeTransactionList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            const search= credentials.search != null ? "search=" + credentials.search : '';
            const operatorid = credentials.operatorid == "" ||credentials.operatorid == null ? '' : "operatorid=" +credentials.operatorid;
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            const page= credentials.page == ""  || credentials.page == ""? '' : "page="+credentials.page;
            console.log(credentials);
            let r = [
                search,
                operatorid,
                carparkid,
                page
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/wl/payee/transaction/view/all' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getWLPayeeReportList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            
            const rows= credentials.rowresultsperpage == null  || credentials.rowresultsperpage == ""? '' : "rows="+credentials.rowresultsperpage;
            const page= credentials.page != null ? "page=" + credentials.page : '';
            const search= credentials.search != null ? "search=" + credentials.search : '';
            const operatorid = credentials.operatorid == "" ||credentials.operatorid == null ? '' : "operatorid=" +credentials.operatorid;
            const carparkid= credentials.carparkid == ""  || credentials.carparkid == ""? '' : "carparkid="+credentials.carparkid;
            const start= credentials.transactionstartdate == ""  || credentials.transactionstartdate == ""? '' : "start="+credentials.transactionstartdate;
            const end= credentials.transactionenddate == ""  || credentials.transactionenddate == ""? '' : "end="+credentials.transactionenddate;
            console.log(credentials);
            let r = [
                rows,
                page,
                search,
                operatorid,
                carparkid,
                start, 
                end
            ];
            let urlReq = urlreq.createReq(r);
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/wl/payee/report/view/all' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getPDF(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            
            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/parking/regenerate/invoicereceipt/' + credentials.ticketid, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getWLPDF(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            const input = {
                trxnID: credentials.trxnID,
                carparkID: credentials.carparkID,
                paymentGateway: credentials.paymentGateway,
            };
            
            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/parking/regenerate/wl',JSON.stringify(input), headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getPTPDF(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            const input = {
                passRef: credentials.passRef,
                paymentGateWay: credentials.paymentGateWay,
                carparkID: credentials.carparkID
            };
            
            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/parking/regenerate/parkingtoken',JSON.stringify(input), headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getVPPDF(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            const input = {
                passRef: credentials.passRef,
                paymentGateWay: credentials.paymentGateWay,
                carparkID: credentials.carparkID
            };
            
            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/parking/regenerate/valuepass',JSON.stringify(input), headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },

        forcedownWheellock(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            let rq = {
                "validatorID": null,
                "wllockID": credentials.wllockID,
                "carparkID": credentials.carparkID
            }

              return new Promise((resolve, reject) => {
                  axios.post(wsutil.state.apiBaseUrl + 'api/wheellock/wllock-control/unlock', JSON.stringify(rq), headers)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(function(error){
                      reject(error);
                  });
              });
        },

        getLhdnSupplierList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            const search= credentials.search != null && credentials.search != "" ? "search=" + credentials.search : '';
            const page= credentials.page != null ? "page=" + credentials.page : '';
            let r = [
                search,
                page
            ];

            let urlReq = urlreq.createReq(r);

            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/config_einvoice/supplier/list' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        lhdnIdentifierList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/config_einvoice/identifier/list', headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        lhdnMsicList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/config_einvoice/msic/list', headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        lhdnStateList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/config_einvoice/state/list', headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        lhdnCountryList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/config_einvoice/country/list', headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        validateTin(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            const input = {
                idType: credentials.idType,
                idValue: credentials.idValue,
                tin: credentials.tin
            };
            
            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/config_einvoice/validatetaxpayer',JSON.stringify(input), headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        addLhdnSetup(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            return new Promise((resolve, reject) => {
                axios.post(wsutil.state.apiBaseUrl + 'api/config_einvoice/supplier/add',JSON.stringify(credentials), headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        updateLhdnSetup(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            const supplierID = credentials.supplierId;
            return new Promise((resolve, reject) => {
                axios.put(wsutil.state.apiBaseUrl + 'api/config_einvoice/supplier/'+supplierID+'/update',credentials, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        deleteLhdnSetup(context, credentials){
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            }; 
            const supplierID = credentials.supplierId;
            return new Promise((resolve, reject) => {
                axios.delete(wsutil.state.apiBaseUrl + 'api/config_einvoice/suppplier/'+supplierID+'/delete', headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },

        getLhdnBillList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/einvoice/bills/list', headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getLhdnDocumentList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            const rows= credentials.rowresultsperpage == null  || credentials.rowresultsperpage == ""? '' : "rows="+credentials.rowresultsperpage;
            const page= credentials.page != null ? "page=" + credentials.page : '';
            const search= credentials.search != null || credentials.search != "" ? "search=" + credentials.search : '';
            const operatorid = credentials.operatorID == "" ||credentials.operatorID == null ? '' : "operatorID=" +credentials.operatorID;
            const carparkID= credentials.carparkID == ""  || credentials.carparkID == "" || credentials.carparkID == undefined? '' : "carparkID="+credentials.carparkID;
            const start= credentials.startDate == ""  || credentials.startDate == ""? '' : "startDate="+moment(credentials.startDate).unix();
            const end= credentials.endDate == ""  || credentials.endDate == ""? '' : "endDate="+moment(credentials.endDate).unix();
            let r = [
                rows,
                page,
                search,
                operatorid,
                carparkID,
                start, 
                end
            ];
            let urlReq = urlreq.createReq(r);

            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/einvoice/consolidated_document/list' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getLhdnSubmissionList(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };

            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/einvoice/submission/list', headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
        getLhdnInvoiceDocuments(context, credentials){
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            const headers = {
                headers: {'Authorization': `JWT ${AppStorage.get("jwtToken")}`}
            };
            const rows= credentials.rowresultsperpage == null  || credentials.rowresultsperpage == ""? '' : "rows="+credentials.rowresultsperpage;
            const page= credentials.page != null ? "page=" + credentials.page : '';
            const search= credentials.search != null || credentials.search != "" ? "search=" + credentials.search : '';
            const operatorid = credentials.operatorid == "" ||credentials.operatorid == null ? '' : "operatorid=" +credentials.operatorid;
            const carparkid= credentials.carparkID == ""  || credentials.carparkID == "" || credentials.carparkID == undefined? '' : "carparkID="+credentials.carparkID;
            const supplierId= credentials.supplierId == ""  || credentials.supplierId == "" || credentials.supplierId == undefined? '' : "supplierId="+credentials.supplierId;
            const start= credentials.startDate == ""  || credentials.startDate == ""? '' : "startDate="+moment(credentials.startDate).unix();
            const end= credentials.endDate == ""  || credentials.endDate == ""? '' : "endDate="+moment(credentials.endDate).unix();
            let r = [
                rows,
                page,
                search,
                operatorid,
                carparkid,
                start, 
                end,
                supplierId
            ];
            let urlReq = urlreq.createReq(r);

            return new Promise((resolve, reject) => {
                axios.get(wsutil.state.apiBaseUrl + 'api/einvoice/document/list' + urlReq, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(function(error){
                    reject(error);
                })
            })
        },
    }
});

//Store current action
wsutil.subscribeAction((action, state) => {
  //if (action.type != 'refreshJWT'){
      state.curAct = action.type;
  //}
  state.temp = action.type;
});

// Add jwt token if token in state in available
/*
axios.interceptors.request.use((setting) => {
  if (AppStorage.get("jwtToken") != null){
      setting.headers['Authorization'] = 'JWT ' + AppStorage.get("jwtToken");
  }
  return setting;
});
*/