<template>
  <v-row justify="center">
    <v-dialog v-model="dialogLocal" max-width="350px">
        <v-card>
            <v-form ref="loginForm" action="#" @submit.prevent="validateBeforeSubmit" lazy-validation>
            <v-card-title class="justify-space-between" style="background-color: #3A3335; color: white">
            <span class="headline">Reset Password</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="text-center">
                            <v-text-field required :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Please enter an email address']" v-model="user.name" light="light" prepend-icon="fa fa-user" label="Email" type="text"></v-text-field>              
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn tile type="submit" small color="primary" :loading="loading" :disabled="loading">
                                    Reset Password
                                    <template v-slot:loader>
                                        <span>Loading...</span>
                                    </template>
                            </v-btn>    
            <v-btn tile small dark color="red darken-1" @click="$emit('closed', false)">Close</v-btn>
            </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
    <alertbox :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox>
    <v-snackbar
        v-model="toaster"
        :color="toasterColor"
        top
        >
        {{ toastertxt }}
    </v-snackbar>
  </v-row>
</template>

<script>
import alertbox from './Notification';
import timer from '../../assets/js/sleeper';

  export default {
    props: ['dialog', 'content'],

    data() {
        return {
            dialogLocal: false,
            user: {
                name: ''
            },
            loading: false,
            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },
            toaster: false,
            toastertxt: '',
            toasterColor: 'red'
        }
    },

    components: {
        alertbox
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
        },
        },

        dialogLocal: {
        handler: function(value) {
            if (value == false)
                this.user.name = '';

            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async userReset(){
            this.loading = true

            try{
                await timer.sleep(800);
                let response = await this.$store.dispatch("userReset", {
                                    username: this.user.name,
                                    })
                this.$emit('closed', false);
                if (response.data.code === 'AP000')
                {
                    this.loading = false;
                    this.ex.modal = true;
                    this.ex.content.headline = 'Password Reset';
                    this.ex.content.icon = 'fa fa-check';
                    this.ex.content.chipColor = '#a0e8af';
                    this.ex.content.iconColor = 'green';
                    this.ex.content.maintext = 'Please check your email for reset instructions.';
                    this.ex.content.smalltext = 'You may now close this screen.';
                }

                else if (response.data.code === '301')
                {
                    this.loading = false;
                    this.toaster = true;
                    this.toastertxt = "This email is already registered";
                }

                else if (response.data.code === '303')
                {
                    this.loading = false;
                    this.toaster = true;
                    this.toastertxt = "This email is not registered";
                }

                else
                {
                    this.loading = false;
                    this.toaster = true;
                    this.toastertxt = "Something went wrong";
                }
            }

            catch(err){
                this.loading = false;
                this.toaster = true;
                this.toastertxt = "API Error";
            }
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false)
            this.$emit('closed', false);
        },

        validateBeforeSubmit() {
            if (this.$refs.loginForm.validate()) {
                this.userReset();
            }
        },

        onClosed(value){
            if (value == false){
                this.autoRefresh = 1;
                this.ex.modal = value;
            }
        },
    }
  }
</script>