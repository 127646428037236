import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {wsutil} from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import titleMixin from './assets/js/titleMixin';
import * as VueGoogleMaps from 'vue2-google-maps'
import VCalendar from 'v-calendar';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import VueMask from 'v-mask';
const ImageZoom = require('vue-image-zoomer').default;

Vue.component('image-zoom', ImageZoom);

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.mixin(titleMixin);

Vue.config.productionTip = false

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VueMask);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAngsb_UC9IeYS1GAWy3Nv2_pRrsd245LY',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

new Vue({
  router,
  store: wsutil,
  vuetify,
  render: h => h(App)
}).$mount('#app')
